import "./DataCatalog.css";
import TagFilter from "../../../../utilities/TagFilter/TagFilter";
import { toast } from "./../../../../utilities/Toast";
import useCatalogData from "./useCatalogData";
import {
  useContext,
  useMemo,
  useState,
  useCallback,
  useRef,
  useEffect,
} from "react";
import { DataContext } from "../../../../../context/DataContext";
import { TagContext } from "../../../../../context/TagContext";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faAmazon, faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import {
  faFile,
  faArrowLeft,
  faFileUpload,
} from "@fortawesome/free-solid-svg-icons";
import {
  runningTasksAtom,
  selectedTagKeysAtom,
  selectedCatalogItemsAtom,
  documentTaggingTaskAtom,
} from "../../../../../atoms";
import { useAtom } from "jotai";
import { debounce } from "lodash";
import { waitTaskDone } from "../../../../../utils/workers";
import { ENDPOINTS } from "../../../../../api/endpoints";
import { sendRequest } from "../../../../utilities/functions/api";
import Auth from "../../../../../auth/AuthProvider";
import { SchedulingModalContent } from "../../../../utilities/SchedulingModalContent";
import FolderList from "../../../../utilities/NavigationBar/FolderList";
import { FileUploadModal } from "../../../../utilities/FileUploadModal";
import { PermissionGuard } from "../../../../utilities/PermissionGuard";

const TagDefinitions = () => {
  const {
    deleteAllLabels,
    showScreen,
    availableTags,
    preferences,
    failedTags,
    tagsToBeDeleted,
    ruleDict,
    currentDataGroup,
    usedCatalog,
    setShowFilePreview,
    catalogGetsRenamed,
  } = useContext(DataContext);

  const {
    relatedInfo,
    setProcessingTags,
    processingTags,
    updateNonEditedTags,
    setProcessingTagTasks,
    autoStandardizeTagsWithoutAvailableValues,
    setProcessingTagTaskProgress,
  } = useContext(TagContext);
  const [showOptions, setShowOptions] = useState(false);

  const [selectedTagKeys, setSelectedTagKeys] = useAtom(selectedTagKeysAtom);
  const [selectedCatalogItems] = useAtom(selectedCatalogItemsAtom);
  const [runningTasks, setRunningTasks] = useAtom(runningTasksAtom);
  const [isTimeSelectionModalOpen, setTimeSelectionModalOpen] = useState(false);
  const [isDataSelectionModalOpen, setDataSelectionModalOpen] = useState(false);
  const [schedulingOptions, setSchedulingOptions] = useState({});
  const { setIsActiveAction } = useContext(TagContext);
  const [searchTerm, handleSearchChange] = useState("");
  const [folders, setFolders] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [checkedItems, setCheckedItems] = useState({});
  const [searchText, setSearchText] = useState("");
  const [integration, setIntegration] = useState("s3");
  const [filePreviewContent, setFilePreviewContent] = useState(false);
  const [filteredFolderKeys, setFilteredFolderKeys] = useState([]);
  const [isModalLoading, setModalLoading] = useState(false);
  const [showDataSourceModal, setShowDataSourceModal] = useState(false);
  const [isFileUploadVisible, setFileUploadVisible] = useState(false);
  const [allSelectedTagsValid, setAllSelectedTagsValid] = useState(true);

  const [_, setDocumentTaggingTask] = useAtom(documentTaggingTaskAtom);

  const tagsShown = useMemo(() => {
    return Object.keys({
      ...availableTags.llm.tagger_params.tag_dict,
      ...ruleDict,
    }).filter((key) => {
      return searchTerm
        ? key.toLowerCase().includes(searchTerm.toLowerCase())
        : key !== "file_directory" &&
            !Object.keys(preferences.system.SENSITIVITY_TAGS).includes(key) &&
            !preferences.system.EXCLUDE_TAGS.includes(key);
    });
  }, [
    availableTags.llm.tagger_params.tag_dict,
    ruleDict,
    searchTerm,
    preferences.system.SENSITIVITY_TAGS,
    preferences.system.EXCLUDE_TAGS,
  ]);

  useEffect(() => {
    const isValid = selectedTagKeys.some(
      (key) =>
        (1 -
          relatedInfo[key]?.counter /
            Object.keys(currentDataGroup).length) *
        100,
    );

    setAllSelectedTagsValid(isValid);
  }, [selectedTagKeys]);

  const handleConnectToDatabase = () => {
    setTimeSelectionModalOpen(false);
    setShowDataSourceModal(true);
  };

  const handleIntegrationClick = (key) => {
    setIntegration(key);
    fetchFolders(key);
    setShowDataSourceModal(false);
    setDataSelectionModalOpen(true);
  };

  const handleBackClick = () => {
    setDataSelectionModalOpen(false);
    setShowDataSourceModal(true);
  };

  const fetchFolders = async (integration) => {
    let dataStore = preferences.webapp_profile.DATA_STORES[integration];
    setModalLoading(true);
    try {
      const folderResponse = await sendRequest(
        {
          data_store: JSON.stringify(dataStore),
          [preferences.system.API_USERNAME_KEYWORD]: (
            await Auth.currentAuthenticatedUser()
          ).username,
        },
        ENDPOINTS["fetch_folders"],
      );
      const retrievedFolders = await folderResponse.json();

      setFolders(retrievedFolders.folder_list);
    } catch (error) {
      console.error("Error during the request", error);
    } finally {
      setModalLoading(false);
    }
  };

  const listContainerRef = useRef();

  useEffect(() => {
    const debouncedSearch = debounce(() => {
      const folderKeys = Object.keys(folders);
      setFilteredFolderKeys(
        folderKeys.filter(
          (folderKey) =>
            folderKey.toLowerCase().includes(searchText.toLowerCase()) ||
            folders[folderKey].some((file) =>
              file.toLowerCase().includes(searchText.toLowerCase()),
            ),
        ),
      );
    }, 300);

    debouncedSearch();

    return () => {
      debouncedSearch.cancel();
    };
  }, [searchText, folders]);

  useEffect(() => {
    if (isDataSelectionModalOpen) {
      const initialCheckedItems = {};
      selectedCatalogItems.forEach((item) => {
        initialCheckedItems[item] = { isChecked: true };
      });
      setCheckedItems(initialCheckedItems); // Directly setting the state
    }
  }, [isDataSelectionModalOpen, selectedCatalogItems, setCheckedItems]);

  const totalUntaggedDocuments = useMemo(() => {
    let total = 0;

    Object.entries(relatedInfo).forEach(([key, info]) => {
      if (selectedTagKeys.length === 0 || selectedTagKeys.includes(key)) {
        total += info.counter;
      }
    });

    return total;
  }, [relatedInfo, selectedTagKeys]);

  const handleScheduleTaskByChunk = async () => {
    const entries = [];
    setIsActiveAction(true);

    const availableTagsCopy = JSON.parse(JSON.stringify(availableTags));
    for (const tagKey in availableTagsCopy?.llm?.tagger_params?.tag_dict ||
      {}) {
      if (selectedTagKeys.length === 0 || selectedTagKeys.includes(tagKey))
        continue;
      delete availableTagsCopy.llm.tagger_params.tag_dict[tagKey];
    }

    const creds = (await Auth.currentAuthenticatedUser()).username;
    let endpoint, params;
    if (
      schedulingOptions.schedulingPeriod &&
      schedulingOptions.schedulingPeriod !== "" &&
      schedulingOptions.schedulingPeriod !== '""'
    ) {
      endpoint = ENDPOINTS["schedule_periodic_catalog_creation_bulk"];
      params = {
        schedule_at: schedulingOptions.scheduleAt.toISOString(),
        period: schedulingOptions.schedulingPeriod,
      };
    } else {
      endpoint = ENDPOINTS["schedule_catalog_creation_bulk"];
      params = {
        schedule_at: schedulingOptions.scheduleAt.toISOString(),
      };
    }
    const dataSnapShot = { ...currentDataGroup };

    const selectedFiles = Object.keys(checkedItems).filter(
      (file) => checkedItems[file].isChecked,
    );
    for (const file_name of selectedFiles) {
      let fileDetails = dataSnapShot[file_name] || {};
      let dataStore =
        preferences.webapp_profile.DATA_STORES[checkedItems[file_name].source];

      let path, data_store_details;
      if (fileDetails.file_directory) {
        path = `${fileDetails.file_directory}/${file_name}`;
        const catalogItem = dataSnapShot[file_name];
        data_store_details = JSON.stringify({
          ...preferences.webapp_profile.DATA_STORES[
            catalogItem.data_store_name
              ? catalogItem.data_store_name
              : catalogItem.storage_type
          ],
          path: `${catalogItem.file_directory}/${file_name}`,
        });
      } else {
        const { source, folder } = checkedItems[file_name];
        let baseDirectory = dataStore.base_path;
        if (baseDirectory[baseDirectory.length - 1] !== "/") {
          baseDirectory += "/";
        }
        const subDirectory = folder === "/" ? "" : folder;
        data_store_details = JSON.stringify({
          ...dataStore,
          path: `${baseDirectory}${subDirectory}${file_name}`,
        });
      }
      const sendChunkObject = {
        data_store: data_store_details,
        tagger_list: JSON.stringify(availableTagsCopy),
        [preferences.system.API_USERNAME_KEYWORD]: creds,
        file_catalog_entry: JSON.stringify({ [file_name]: {} }),
        catalog_name: usedCatalog,
        quarantine_name: preferences.system.QUARANTINECATALOG,
        check_sensitivity: false,
      };

      entries.push(sendChunkObject);
    }
    console.log("entries", entries);

    try {
      const sendDetails = {
        ...params,
        entries,
        [preferences.system.API_USERNAME_KEYWORD]: creds,
        preferences: JSON.stringify(preferences),
        period: JSON.stringify(schedulingOptions.schedulingPeriod || ""),
      };
      console.log("..endpoint", endpoint);
      console.log("..sendDetails", sendDetails);
      await sendRequest(sendDetails, endpoint);
      toast.success({
        title: `Labeling scheduling at ${new Date(schedulingOptions.scheduleAt).toLocaleDateString()}`,
      });
    } catch (error) {
      console.error("Error during scheduling setup:", error);
      toast.error({
        title: `Labeling scheduled at ${new Date(schedulingOptions.scheduleAt).toLocaleDateString()} failed`,
      });
    } finally {
      setDataSelectionModalOpen(false);
      setIsActiveAction(false);
    }
  };

  const runSelectedTags = useCallback(
    async (rerun = false) => {
      const taskId = `runAllTags-${Date.now()}`;
      const entries = [];
      // Deep copy to avoid mutations
      const availableTagsCopy = JSON.parse(JSON.stringify(availableTags));
      const tagTimeStamp = new Date().toISOString();
      for (const tagKey in availableTagsCopy?.llm?.tagger_params?.tag_dict ||
        {}) {
        if (selectedTagKeys.length === 0 || selectedTagKeys.includes(tagKey)) {
          availableTagsCopy.llm.tagger_params.tag_dict[tagKey].tagged_at =
            tagTimeStamp;
        } else {
          delete availableTagsCopy.llm.tagger_params.tag_dict[tagKey];
        }
      }
      const dataSnapShot = { ...currentDataGroup };

      // Add new tasks to the processing tags for each tag
      const allTags = Object.keys(
        availableTags.llm.tagger_params.tag_dict,
      ).filter((tagKey) => {
        return selectedTagKeys.length === 0 || selectedTagKeys.includes(tagKey);
      });
      setProcessingTags((prev) => [
        ...prev,
        ...allTags.map((tag) => ({ label: tag })),
      ]);

      const selectedFiles = Array.from(selectedCatalogItems);

      // Iterate through each file and prepare entries
      for (const file_name of Object.keys(dataSnapShot)) {
        if (selectedFiles.length === 0 || selectedFiles.includes(file_name)) {
          const catalogItem = dataSnapShot[file_name];
          const sendChunkObject = {
            data_store: JSON.stringify({
              ...preferences.webapp_profile.DATA_STORES[
                catalogItem.data_store_name
                  ? catalogItem.data_store_name
                  : catalogItem.storage_type
              ],
              path: `${catalogItem.file_directory}/${file_name}`,
            }),
            tagger_list: JSON.stringify(availableTagsCopy),
            file_catalog_entry: JSON.stringify({ [file_name]: {} }),
            catalog_name: usedCatalog,
            quarantine_name: preferences.system.QUARANTINECATALOG,
            check_sensitivity: false,
          };

          entries.push(sendChunkObject);
        }
      }

      // Ensure state updates before async operations
      setRunningTasks((tasks) => [
        ...tasks,
        {
          id: taskId,
          process: "Tagging",
          description: "Running " + selectedTagKeys.length + " tags",
          completed: 0,
        },
      ]);

      try {
        const creds = (await Auth.currentAuthenticatedUser()).username;
        const res = await sendRequest(
          {
            entries,
            [preferences.system.API_USERNAME_KEYWORD]: creds,
            preferences: JSON.stringify(preferences),
          },
          rerun ? ENDPOINTS["rerun_tag"] : ENDPOINTS["create_catalog_in_bulk"],
        );
        const { task_id } = await res.json();

        setRunningTasks((tasks) => {
          const updatedTask = tasks.find((task) => task.id === taskId);
          if (updatedTask) {
            updatedTask.id = task_id;
          }
          return [...tasks];
        });

        setProcessingTagTaskProgress(0);
        setDocumentTaggingTask(task_id);

        // Monitor task progress
        waitTaskDone(task_id, creds, undefined, ({ completed }) => {
          setRunningTasks((tasks) => {
            const updatedTask = tasks.find((task) => task.id === taskId);
            if (updatedTask) {
              updatedTask.completed = completed;
            }
            return [...tasks];
          });
          setProcessingTagTaskProgress(completed);
        }).then(() => {
          setRunningTasks((tasks) => {
            const updatedTask = tasks.find((task) => task.id === task_id);
            if (updatedTask) {
              updatedTask.completed = 1;
            }
            return [...tasks];
          });

          setProcessingTags((prev) =>
            prev.filter(({ label }) => !allTags.includes(label)),
          );
          setProcessingTagTasks((prev) => {
            const newMap = new Map(prev);
            allTags.forEach((tag) => {
              newMap.delete(tag);
            });
            return newMap;
          });
          autoStandardizeTagsWithoutAvailableValues();
          setDocumentTaggingTask(null);
        });

        await updateNonEditedTags(availableTagsCopy.llm.tagger_params.tag_dict);
      } catch (error) {
        console.error("Error running all tags:", error);
        toast.error({
          title: "Error",
          description: "There was an issue processing the tags.",
        });

        setProcessingTags((prev) =>
          prev.filter(({ label }) => !allTags.includes(label)),
        );
        setProcessingTagTasks((prev) => {
          const newMap = new Map(prev);
          allTags.forEach((tag) => {
            newMap.delete(tag);
          });
          return newMap;
        });
      }
    },
    [
      availableTags,
      currentDataGroup,
      setProcessingTags,
      setRunningTasks,
      selectedTagKeys,
      preferences.webapp_profile.DATA_STORES,
      preferences.system.QUARANTINECATALOG,
      preferences.system.API_USERNAME_KEYWORD,
      usedCatalog,
      setProcessingTagTasks,
    ],
  );

  const abortTagging = useCallback(
    async (taskId) => {
      const creds = (await Auth.currentAuthenticatedUser()).username;
      try {
        await sendRequest(
          {
            task_id: taskId,
            [preferences.system.API_USERNAME_KEYWORD]: creds,
          },
          ENDPOINTS.revoke_task,
        );
        return true;
      } catch (error) {
        console.error("Error aborting the task:", error);
        return false;
      }
    },
    [preferences],
  );

  const tagDict = useMemo(() => {
    return {
      ...availableTags.llm.tagger_params.tag_dict,
      ...ruleDict,
    };
  }, [availableTags.llm.tagger_params.tag_dict, ruleDict]);

  const abortAllTags = useCallback(async () => {
    const allTags = Object.keys(availableTags.llm.tagger_params.tag_dict);
    const task = runningTasks.find(
      // TODO: handle abort here
      (task) =>
        task.description === "Running " + selectedTagKeys.length + " tags" &&
        task.completed !== 1,
    );

    if (task) {
      try {
        const success = await abortTagging(task.id);
        if (success) {
          setRunningTasks((tasks) => tasks.filter((t) => t.id !== task.id));
          setProcessingTags((prev) =>
            prev.filter(({ label }) => !allTags.includes(label)),
          );
          setProcessingTagTasks((prev) => {
            const newMap = new Map(prev);
            allTags.forEach((tag) => {
              newMap.delete(tag);
            });
            return newMap;
          });
          toast.info({
            title: "Process Stopped",
            description: "The tagging process has been successfully stopped.",
          });
        } else {
          toast.error({
            title: "Error",
            description: "Failed to stop the tagging process.",
          });
        }
      } catch (error) {
        console.error("Error aborting all tags:", error);
        toast.error({
          title: "Error",
          description: "Failed to stop the tagging process.",
        });
      }
    } else {
      toast.warning({
        title: "Warning",
        description: "No active tagging process found to stop.",
      });
    }
  }, [
    runningTasks,
    availableTags,
    setRunningTasks,
    setProcessingTags,
    setProcessingTagTasks,
    abortTagging,
  ]);

  if (catalogGetsRenamed) {
    return (
      <div className="h-full shrink-0 grow-0 flex flex-col bg-zinc-100 rounded-md overflow-hidden justify-center items-center">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-primary"></div>
        <p className="mt-4 text-lg font-semibold">Loading available tags...</p>
      </div>
    );
  }

  return (
    <>
      {showDataSourceModal && (
        <div className="Modal z-50 backdrop-blur-md">
          <div className="ModalContent">
            <div className="ModalHeader flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">Connect to Data Source</h2>
              <button
                className="text-lg"
                onClick={() => setShowDataSourceModal(false)}
              >
                &times;
              </button>
            </div>
            <div className="ModalOptions">
              {Object.entries(preferences.webapp_profile.DATA_STORES).map(
                ([key, value]) => (
                  <div
                    key={key}
                    className="Option"
                    onClick={() => handleIntegrationClick(key, "s3List")} // Assuming the view is always "s3List" for simplicity
                  >
                    <FontAwesomeIcon
                      icon={
                        value["storage"]["type"] === "s3"
                          ? faAmazon
                          : value["storage"]["type"] === "sharepoint"
                            ? faMicrosoft
                            : faFile
                      }
                      className={
                        value["storage"]["type"] === "s3"
                          ? "Icon AmazonIcon"
                          : "Icon SharePointIcon"
                      }
                    />
                    {`Connect to ${key}`}
                  </div>
                ),
              )}
            </div>
          </div>
        </div>
      )}
      {isTimeSelectionModalOpen && (
        <div className="Modal z-50 backdrop-blur-md">
          <div className="ModalContent">
            <div className="ModalHeader flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">
                Pick a date and time to run the task
              </h2>
              <button
                className="text-lg"
                onClick={() => setTimeSelectionModalOpen(false)}
              >
                &times;
              </button>
            </div>
            <div className="w-full mb-4">
              <SchedulingModalContent
                onChange={(newOptions) => {
                  setSchedulingOptions(newOptions);
                }}
              />
            </div>
            <div className="flex justify-end">
              <button
                className="bg-primary hover:bg-primary text-white font-bold py-2 px-4 rounded"
                onClick={handleConnectToDatabase}
              >
                {/* Schedule the Task */}
                Connect to Database
              </button>
            </div>
          </div>
        </div>
      )}
      {isDataSelectionModalOpen && (
        <div className="Modal z-50 backdrop-blur-md">
          <div className="ModalContent">
            <div className="ModalHeader flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">Select Data Source</h2>
              <button
                className="text-lg"
                onClick={() => setDataSelectionModalOpen(false)}
              >
                &times;
              </button>
            </div>
            <div className="w-full h-full overflow-auto">
              <div
                className="h-full w-full overflow-hidden"
                ref={listContainerRef}
              >
                <div className="w-full h-full overflow-hidden">
                  <FolderList
                    folders={folders}
                    currentFolder={currentFolder}
                    checkedItems={checkedItems}
                    searchText={searchText}
                    setCurrentFolder={setCurrentFolder}
                    setCheckedItems={setCheckedItems}
                    integration={integration}
                    setFilePreviewContent={setFilePreviewContent}
                    setShowFilePreview={setShowFilePreview}
                    filteredFolderKeys={filteredFolderKeys}
                    setSearchText={setSearchText}
                  />
                </div>
              </div>
            </div>
            <div className="flex w-full justify-between items-center">
              <button
                onClick={handleBackClick}
                className="cursor-pointer mt-4 bg-grey hover:bg-grey text-white font-bold py-2 px-4 rounded"
              >
                <FontAwesomeIcon icon={faArrowLeft} /> Back
              </button>
              <p>{Object.keys(checkedItems).length} Files selected</p>
              {/* {console.log("Checked Items:", checkedItems)} */}
              <div className="flex items-right">
                {["s3", "azureblob"].includes(integration) && (
                  <button
                    onClick={() => setFileUploadVisible(true)}
                    disabled={isFileUploadVisible || isModalLoading}
                    className="UploadFileBtn border-2 border-primary rounded-md"
                  >
                    <FontAwesomeIcon icon={faFileUpload} />
                    <span className="text-primary">Manual upload</span>
                  </button>
                )}
                <button
                  className="RefreshButton"
                  onClick={handleScheduleTaskByChunk}
                  disabled={Object.keys(checkedItems).length === 0}
                >
                  Schedule
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isFileUploadVisible && (
        <FileUploadModal
          dataStore={{
            ...preferences.webapp_profile.DATA_STORES[integration].storage,
            base_path:
              preferences.webapp_profile.DATA_STORES[integration].base_path,
          }}
          onClose={() => setFileUploadVisible(false)}
          onSuccess={() => {
            fetchFolders(integration);
          }}
        />
      )}
      <div className="h-full shrink-0 grow-0 flex flex-col bg-zinc-100 rounded-md overflow-hidden">
        <div className="p-3 flex items-center shrink-0 bg-slate-200 dark:bg-zinc-600 dark:text-white">
          <i className="fas fa-search text-gray-500 dark:text-gray-400 mr-3 "></i>
          <div className="text-lg font-bold">Available Tags</div>
        </div>
        <input
          type="text"
          placeholder="Search Tags..."
          value={searchTerm}
          onChange={(e) => handleSearchChange(e.target.value)}
          className="search-bar p-4 text-sm outline-none w-full border"
        />
        <div className="flex">
          <PermissionGuard scope="catalogs" level="canEdit">
          <button
            className="bg-white rounded-md text-primary border-2 border-primary p-2 m-5 me-2"
            style={{ flex: "1 1 auto" }}
            onClick={() => {
              if (selectedTagKeys.length) {
                return setSelectedTagKeys([]);
              }
              setSelectedTagKeys(
                Object.keys(availableTags?.llm?.tagger_params?.tag_dict || {}),
              );
            }}
          >
              {selectedTagKeys.length > 0 ? "Deselect all" : "Select all"}
            </button>
            </PermissionGuard>
          <PermissionGuard  scope="tags" level="canEdit">
            <button
              className="bg-white rounded-md text-red-500 hover:text-red-600 border-2 border-red-500 p-2 m-5 ms-2"
              onClick={(e) =>
                deleteAllLabels(e, selectedTagKeys).then(() =>
                  setSelectedTagKeys([]),
                )
              }
              style={{ opacity: selectedTagKeys.length === 0 ? 0.5 : 1 }}
              disabled={selectedTagKeys.length === 0}
              title="Delete selected tags"
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          </PermissionGuard>
          </div>
          <div className="flex flex-col overflow-y-auto overflow-x-hidden h-full ">
            {tagsShown
              .sort((tagAKey, tagBKey) => {
                const tagA = tagDict[tagAKey];
                const tagB = tagDict[tagBKey];

                if (!tagA || !tagB) {
                  return 0;
                }

                if (!tagA.updated_at) {
                  tagA.updated_at = new Date("1976/01/01").toISOString();
                }
                if (!tagB.updated_at) {
                  tagB.updated_at = new Date("1976/01/01").toISOString();
                }

                return (
                  new Date(tagB.updated_at).getTime() -
                  new Date(tagA.updated_at).getTime()
                );
              })
              .map((key) => {
                return (
                  <div
                    className="relative cursor-pointer"
                    onClick={() => {
                      if (!selectedTagKeys.includes(key)) {
                        setSelectedTagKeys((prev) => [...prev, key]);
                      } else {
                        setSelectedTagKeys((prev) =>
                          prev.filter((_key) => _key !== key),
                        );
                      }
                    }}
                  >

                    {selectedTagKeys.includes(key) && (
                      <div className="inset-0 bg-green-600 bg-opacity-10 absolute z-10 pointer-events-none"></div>
                    )}
                    <div className="px-2 bg-white flex justify-between">
                      <PermissionGuard scope="catalogs" level="canEdit">
                      <input
                      type="checkbox"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedTagKeys((prev) => [...prev, key]);
                        } else {
                          setSelectedTagKeys((prev) =>
                            prev.filter((_key) => _key !== key),
                          );
                        }
                      }}
                      checked={selectedTagKeys.includes(key)}
                      onClick={(e) => e.stopPropagation()}
                    />
                    </PermissionGuard>
                    <div>
                      {failedTags.has(key) && (
                        <FontAwesomeIcon
                          icon={faExclamationCircle}
                          className="text-yellow-500 text-sm"
                          title={`Tag has been failed on ${failedTags.get(key).length} document(s) - try re-run:\n${failedTags.get(key).join("\n")} `}
                        />
                      )}
                      {(!tagDict[key].tagged_at ||
                        new Date(tagDict[key].tagged_at) <
                          new Date(tagDict[key].updated_at)) && (
                        <div
                          className="h-100 right-3 top-3 highlighted-tag"
                          title="Tag has been updated since last tagging"
                        />
                      )}
                    </div>
                  </div>
                  <TagFilter
                    label={key}
                    categoryKey={key}
                    showScreen={showScreen}
                    isBeingDeleted={tagsToBeDeleted.includes(key)}
                    selectedTagKeys={selectedTagKeys}
                    setSelectedTagKeys={setSelectedTagKeys}
                  ></TagFilter>
                </div>
              );
            })}
        </div>
        <PermissionGuard scope="catalogs" level="canEdit">
        <div className="bg-slate-200 dark:bg-zinc-600 flex flex-col w-full">
          <div className="flex flex-row w-full justify-between">
            {selectedTagKeys.length > 0 && (
              <div className="bg-slate-100 border w-full justify-between flex flex-row items-center">
                {processingTags.length > 0 ? (
                  <button
                    className="py-3 px-6 flex flex-row items-center bg-slate-100 justify-end"
                    onClick={abortAllTags}
                  >
                    <p className="text-md bg-red-400 text-white p-2 rounded-md font-bold">
                      Abort All
                    </p>
                  </button>
                ) : (
                  <div
                    className="pl-2 flex w-full"
                    style={{
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    {!showOptions ? (
                      <>
                        <button
                          className="py-3 px-6 justify-between flex flex-row items-center w-full"
                          onClick={() => {
                            setShowOptions(true);
                          }}
                        >
                          <p className="bg-primary  text-white p-3 rounded-md text-md">
                            {selectedTagKeys.length > 0
                              ? `Run ${selectedTagKeys.length} ${selectedTagKeys.length > 1 ? "Tags" : "Tag"}`
                              : "Tag"}{" "}
                            on{" "}
                            {Array.from(selectedCatalogItems).length === 0
                              ? "all document(s)"
                              : `${Array.from(selectedCatalogItems).length} document(s)`}
                          </p>
                        </button>
                        {allSelectedTagsValid && (
                          <button
                            className="py-3 px-6 justify-between flex flex-row items-center w-full"
                            onClick={() => runSelectedTags(true)}
                          >
                            <p className="bg-yellow-500  text-white p-3 rounded-md text-md">
                              {selectedTagKeys.length > 0
                                ? `Re-Run ${selectedTagKeys.length} ${selectedTagKeys.length > 1 ? "Tags" : "Tag"}`
                                : "Tag"}{" "}
                              on{" "}
                              {Array.from(selectedCatalogItems).length === 0
                                ? "all document(s)"
                                : `${Array.from(selectedCatalogItems).length} document(s)`}
                            </p>
                          </button>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="flex flex-row justify-between items-center w-full">
                          <button
                            className="py-3 px-4 flex-grow bg-primary text-white text-md rounded-md"
                            onClick={() => runSelectedTags()}
                            style={{ marginRight: "8px" }}
                            disabled={
                              totalUntaggedDocuments === 0 &&
                              selectedTagKeys.every((tag) => {
                                return (
                                  tagDict[tag] !== undefined &&
                                  tagDict[tag].tagged_at &&
                                  new Date(tagDict[tag].tagged_at) >
                                    new Date(tagDict[tag].updated_at)
                                );
                              })
                            }
                          >
                            Run it now
                          </button>
                          <button
                            className="py-3 px-4 flex-grow bg-white text-primary text-md rounded-md border border-primary"
                            onClick={() => setTimeSelectionModalOpen(true)}
                            style={{ marginRight: "8px" }}
                          >
                            Schedule
                          </button>
                          <button
                            className="py-3 px-4 flex-grow bg-grey text-white text-md rounded-md"
                            onClick={() => setShowOptions(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                )}
                <div className="hidden group-hover:block absolute -top-12 -left-18 w-48 bg-gray-800 text-white p-2 rounded-md z-99">
                  The right number shows you how many datasets have not been
                  tagged with selected tags
                </div>
              </div>
            )}
          </div>
        </div>
        </PermissionGuard>
      </div>
    </>
  );
};

export default TagDefinitions;
