import { useEffect, useState } from "react";

const ScoreCard = ({ tag, evaluatingTag, evaluatedScores }) => {
  const [score, setScore] = useState(null);
  const radius = 36;
  const circumference = 2 * Math.PI * radius;

  useEffect(() => {
    if (evaluatedScores.hasOwnProperty(tag.name)) {
      setScore(evaluatedScores[tag.name]);
    }
  }, [evaluatedScores, tag.name]);

  const getStrokeDashoffset = (score) => {
    return circumference - score * circumference;
  };

  return (
    <div className="text-center mt-4 flex flex-col justify-start w-full items-end space-y-2">
      <div className="p-4 rounded-lg shadow-s border border-gray-200">
        <header className="text-lg font-semibold text-gray-800 mb-2">
          Score
        </header>
        <div className="relative flex justify-center items-center">
          {evaluatingTag === tag.name && (
            <div className="mt-2 me-5 flex items-center">
              <div className="loader border-t-4 border-primary border-solid rounded-full w-6 h-6 animate-spin"></div>
            </div>
          )}
          {evaluatingTag !== tag.name && score !== null ? (
            <div className="relative w-24 h-24">
              <svg className="absolute top-0 left-0 w-full h-full transform -rotate-90">
                <circle
                  className="text-gray-300"
                  strokeWidth="4"
                  stroke="currentColor"
                  fill="transparent"
                  cx="50%"
                  cy="50%"
                  r={radius}
                />
                <circle
                  className="text-primary transition-all duration-300"
                  strokeWidth="4"
                  strokeDasharray={circumference}
                  strokeDashoffset={getStrokeDashoffset(score)}
                  strokeLinecap="round"
                  stroke="currentColor"
                  fill="transparent"
                  cx="50%"
                  cy="50%"
                  r={radius}
                />
              </svg>
              <div className="absolute inset-0 flex items-center justify-center text-lg font-semibold text-primary">
                {(100 * score).toFixed(2)}
              </div>
            </div>
          ) : (
            "Not evaluated"
          )}
        </div>
      </div>
    </div>
  );
};

export default ScoreCard;
