import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";

type PaginatorProps = {
  onNewPage: (page: number) => void;
  onNewPageSize: (page: number) => void;
  currentPage: number;
  pageSize: number;
  numberOfItems: number;
};

const Paginator = ({
  onNewPage,
  onNewPageSize,
  currentPage,
  pageSize,
  numberOfItems,
}: PaginatorProps) => {
  const totalPages = Math.ceil(numberOfItems / pageSize);

  let startPage = Math.max(1, currentPage - 1);
  let endPage = Math.min(startPage + 3, totalPages);

  if (totalPages - currentPage < 3) {
    startPage = Math.max(1, totalPages - 3);
  }

  const pageNumbers = Array.from(
    { length: endPage - startPage + 1 },
    (_, idx) => startPage + idx
  );

  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 select-none rounded-b-md">
      <div className="flex flex-1 justify-between sm:hidden">
        {currentPage > 1 && (
          <div
            onClick={() => onNewPage(currentPage - 1)}
            className="relative cursor-pointer inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700"
          >
            Previous
          </div>
        )}
        {currentPage < totalPages && (
          <div
            onClick={() => onNewPage(currentPage + 1)}
            className="relative cursor-pointer ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700"
          >
            Next
          </div>
        )}
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div className="flex gap-4 items-center">
          <div>
            <p className="text-sm text-gray-700">
              Showing{" "}
              <span className="font-medium">
                {(currentPage - 1) * pageSize + 1}
              </span>{" "}
              to{" "}
              <span className="font-medium">
                {Math.min(currentPage * pageSize, numberOfItems)}
              </span>{" "}
              of <span className="font-medium">{numberOfItems}</span> results
            </p>
          </div>
        </div>
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            {currentPage > 1 && (
              <div
                onClick={() => onNewPage(currentPage - 1)}
                className="relative cursor-pointer inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 focus:z-20 focus:outline-offset-0"
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </div>
            )}
            {pageNumbers.map((page) => (
              <div
                key={page}
                aria-current={page === currentPage ? "page" : undefined}
                className={`relative cursor-pointer inline-flex items-center px-4 py-2 text-sm font-semibold ${page === currentPage ? "bg-deasieTurquoise text-white" : "text-gray-900"} ring-1 ring-inset ring-gray-300 focus:z-20 focus:outline-offset-0`}
                onClick={() => onNewPage(page)}
              >
                {page}
              </div>
            ))}
            {currentPage < totalPages && (
              <div
                onClick={() => onNewPage(currentPage + 1)}
                className="relative cursor-pointer inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 focus:z-20 focus:outline-offset-0"
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </div>
            )}
          </nav>
        </div>
      </div>
      <div className="flex gap-2 text-xs items-center justify-center pl-8">
        <div>Page Size</div>
        <select
          className="border px-2 py-1 outline-none"
          value={pageSize}
          onChange={(e) => onNewPageSize(parseInt(e.target.value, 10))}
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={100}>100</option>
          <option value={100000}>All</option>
        </select>
      </div>
    </div>
  );
};

export default Paginator;
