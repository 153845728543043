import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import Paginator from "../components/Paginator/Paginator";
import { FaExpandArrowsAlt } from "react-icons/fa";
import { FaMinimize } from "react-icons/fa6";
import { BsThreeDotsVertical } from "react-icons/bs";
import { RiTableAltFill } from "react-icons/ri";
import { AvailableTagsResponse, CatalogResponse } from "../types";
import { DataContext, useDataContext } from "../context/DataContext";
import { useAtom } from "jotai";
import { pdfAtom, pdfSearchAtom, selectedCatalogItemsAtom } from "../atoms";
import Workers from "../utils/threading";
import { pdfjs } from "react-pdf";
import { AdvancedTagFiltersModal } from "../components/utilities/AdvancedTagFilters/AdvancedTagFilters";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { useCatalogContext } from "../context/CatalogContext";
import { DocumentViewer } from "../components/utilities/DocumentViewer/DocumentViewer";
import { PermissionGuard } from "../components/utilities/PermissionGuard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { toast } from "../components/utilities/Toast";
import { useCatalogDocumentInfoLoader } from "../api/queryHooks";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const stringToColor = (stringValue: string, opacity = "40") => {
  let hash = 0;
  for (let i = 0; i < stringValue.length; i++) {
    hash = stringValue.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }

  let color = "#";
  for (let i = 0; i < 3; i++) {
    // Use parts of the hash for different color components
    let value = (hash >> (i * 8)) & 0xff;
    color += ("00" + value.toString(16)).slice(-2);
  }
  return color + opacity;
};

type CatalogTableProps = {
  onEvidenceClick: (itemKey: string) => void;
  availableTags: AvailableTagsResponse["tags"];
};

const CatalogTable = ({
  onEvidenceClick,
  availableTags,
}: CatalogTableProps) => {
  const [actionsFile, setActionsFile] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [expandedFiles, setExpandedFiles] = useState(new Set<string>());
  const [pdf, setPdf] = useAtom(pdfAtom);
  const [pdfSearch, setPdfSearch] = useAtom(pdfSearchAtom);
  const [selectedCatalogItems, setSelectedCatalogItems] = useAtom(
    selectedCatalogItemsAtom,
  );

  const {
    setShowAdvancedTagFilters,
    showAdvancedTagFilters,
    taggingFilters,
    setTaggingFilters,
  } = useCatalogContext();

  const [advancedTaggingFilters, setAdvancedTaggingFilters] = useState(
    {} as Record<string, string[]>,
  );
  const [tagFilterOpened, setTagFilterOpened] = useState("");
  const [tagFilterOpenedSearch, setTagFilterOpenedSearch] = useState("");
  const {
    hiddenCategories,
    ruleDict,
    handleDatasetDelete,
    catalogFiles,
    currentDataGroup,
    setSelectedFilters,
    catalogGetsRenamed,
    showFileOnPage,
    setShowFileOnPage,
    isGloballyExpanded,
    setIsGloballyExpanded,
    duplicatesCluster,
  }: {
    currentDataGroup: CatalogResponse[ "catalog"];
    hiddenCategories: any;
    ruleDict: any;
    handleDatasetDelete: any;
    catalogFiles: any;
    setSelectedFilters: any;
    catalogGetsRenamed: boolean;
    showFileOnPage: string;
    setShowFileOnPage: any;
    isGloballyExpanded: boolean;
    setIsGloballyExpanded: any;
    duplicatesCluster: any;
  } = useContext(DataContext);
  useEffect(() => {
    setSelectedFilters(taggingFilters);
  }, [setSelectedFilters, taggingFilters]);

  const { usedCatalog } = useDataContext();
  const loadDocumentInfo = useCatalogDocumentInfoLoader();

  const sortedEntries = useMemo(() => {
    return Object.entries(currentDataGroup || {})
      .slice((currentPage - 1) * pageSize, currentPage * pageSize)
      .sort(([_, fileA], [__, fileB]) => {
        if (!fileA.last_processed_at) {
          fileA.last_processed_at = new Date("1976/01/01").toISOString();
        }
        if (!fileB.last_processed_at) {
          fileB.last_processed_at = new Date("1976/01/01").toISOString();
        }
        return (
          new Date(fileB.last_processed_at).getTime() -
          new Date(fileA.last_processed_at).getTime()
        );
      });
  }, [currentDataGroup, currentPage, pageSize]);

  const getDuplicateParent = (docKey: string) => {
    // check if the length of the cluster is 0
    if (Object.keys(duplicatesCluster).length === 0) {
      return { duplicate: "" };
    }
    // if the cluster is not empty
    if (!duplicatesCluster.files.includes(docKey) || Object.keys(duplicatesCluster.clusters).includes(docKey)) {
      return { duplicate: "" };
    } else {
      // check if the item is in the clusters list of the duplicatesCluster dict
      for (const [key, value] of Object.entries(duplicatesCluster.clusters)) {
        if ((value as any).duplicates.includes(docKey)) {
          return { duplicate: key };
        }
      }
    }
    return { duplicate: "" };
  }

  useEffect(() => {
    (async () => {
      const advancedTaggingFilters = await Workers.invoke(
        "getAdvancedTaggingFilters",
        catalogFiles,
      );
      setAdvancedTaggingFilters(advancedTaggingFilters);
    })();
  }, [catalogFiles]);

  const downloadFile = useCallback(
    async (docKey: string) => {
      if (!currentDataGroup) return;
      toast.info({
        title: "Downloading file...",
        description: "This may take a few seconds.",
      });

      const documentInfo = await loadDocumentInfo(docKey);

      window.open(documentInfo.file_url, "_blank");
      setActionsFile("");
    },
    [currentDataGroup, loadDocumentInfo],
  );

  const openFile = useCallback(
    async (docKey: string) => {
      if (!currentDataGroup) return;

      toast.info({
        title: "Opening file...",
        description: "This may take a few seconds.",
      });

      const documentInfo = await loadDocumentInfo(docKey);
      if (
        documentInfo.file_name.endsWith(".pdf") ||
        documentInfo.file_name.endsWith(".docx")
      ) {
        setShowFileOnPage("standard");
        setPdf(documentInfo.file_url.toString());
      } else {
        window.open(documentInfo.file_url, "_blank");
        setActionsFile("");
      }
    },
    [currentDataGroup, setPdf, setShowFileOnPage, loadDocumentInfo],
  );

  return (
    <div className="flex flex-col w-full h-full items-center overflow-hidden">
      {pdf && showFileOnPage === "standard" && (
        <DocumentViewer
          url={pdf}
          onClose={() => {
            setPdf("");
            setShowFileOnPage(null);
            setPdfSearch("");
          }}
          search={pdfSearch}
        />
      )}
      <div className="w-full flex p-2 bg-white gap-2">
        {Object.keys(taggingFilters).length !== 0 && (
          <div className="w-full flex gap-1 ml-4 items-center">
            {Object.entries(taggingFilters).map(([tag, value]) => (
              <div
                className="relative whitespace-nowrap select-none flex bg-gray-50 rounded-md px-8 py-2 border cursor-pointer"
                onClick={() => {
                  setTagFilterOpened(tag);
                  setTagFilterOpenedSearch("");
                }}
              >
                {tag} {`(${taggingFilters?.[tag]?.size || 0})`}
                {tagFilterOpened === tag && (
                  <>
                    <div className="text-zinc-600 absolute top-[calc(100%_+_4px)] left-0 z-[100] min-w-[200px] max-w-[300px] overflow-hidden bg-white border rounded-md">
                      <div>
                        <input
                          type="text"
                          className="w-full bg-white px-4 py-2 outline-none"
                          placeholder="Search Value"
                          value={tagFilterOpenedSearch}
                          onChange={(e) => {
                            setTagFilterOpenedSearch(e.target.value);
                          }}
                        />
                      </div>
                      <div className="max-h-[200px] overflow-auto w-full">
                        {advancedTaggingFilters &&
                          advancedTaggingFilters.hasOwnProperty(tag) &&
                          advancedTaggingFilters[tag]
                            .filter((value) => {
                              if (tagFilterOpenedSearch) {
                                return value
                                  .toLowerCase()
                                  .includes(
                                    tagFilterOpenedSearch.toLowerCase(),
                                  );
                              }
                              return true;
                            })
                            .map((value, index) => {
                              const isTagValueSelected =
                                taggingFilters[tag]?.has(value);
                              return (
                                <div
                                  className={`px-4 py-2 ${isTagValueSelected ? "bg-slate-600 text-white" : index % 2 === 0 ? "bg-slate-50 hover:bg-slate-100" : "bg-white hover:bg-slate-100"}`}
                                  onClick={() => {
                                    taggingFilters[tag] =
                                      taggingFilters[tag] || new Set();
                                    if (isTagValueSelected) {
                                      taggingFilters[tag].delete(value);
                                    } else {
                                      taggingFilters[tag].add(value);
                                    }
                                    setTaggingFilters({ ...taggingFilters });
                                  }}
                                >
                                  {value}
                                </div>
                              );
                            })}
                      </div>
                    </div>
                    <div
                      className="fixed z-[99] inset-0 cursor-default"
                      onClick={(e) => {
                        e.stopPropagation();
                        setTagFilterOpened("");
                        setTagFilterOpenedSearch("");
                      }}
                    ></div>
                  </>
                )}
                <div className=" absolute top-1 right-2">
                  <button
                    className="font-semibold text-lg"
                    onClick={(e) => {
                      e.stopPropagation();
                      setTaggingFilters((prev) => {
                        delete prev[tag];
                        return { ...prev };
                      });
                    }}
                  >
                    x
                  </button>
                </div>
              </div>
            ))}
            <button
              disabled={Object.values(taggingFilters).flat().length === 0}
              className="border-2 border-primary py-2 px-4 text-primary rounded-md whitespace-nowrap"
              onClick={() => {
                setTaggingFilters({});
              }}
            >
              Clear all
            </button>
          </div>
        )}
      </div>
      {showAdvancedTagFilters && (
        <div className="absolute z-50 inset-0 p-4 bg-black bg-opacity-20 backdrop-blur-sm flex items-center justify-center">
          <div className="w-full max-w-lg max-h-[50vh] p-4 bg-white rounded-md shadow-md flex flex-col gap-4 overflow-hidden">
            <AdvancedTagFiltersModal
              onCancel={() => setShowAdvancedTagFilters(false)}
              onAccept={(newFilters: any) => {
                setTaggingFilters((prev) => ({
                  ...prev,
                  ...Object.fromEntries(
                    newFilters.map((filter: any) => [
                      filter,
                      new Set<string>([]),
                    ]),
                  ),
                }));
                setShowAdvancedTagFilters(false);
              }}
              currentFilters={Object.keys(taggingFilters)}
            />
          </div>
        </div>
      )}

      <div className="w-full h-full bg-white overflow-auto relative">
        {!currentDataGroup && (
          <div className="absolute inset-0 bg-white flex justify-center items-center">
            <div className="text-gray-200 animate-pulse text-6xl">
              <RiTableAltFill />
            </div>
          </div>
        )}
        {!catalogGetsRenamed ? (
          <table className="w-full text-sm text-left rtl:text-right">
            <thead className="text-xs uppercase">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 w-10 top-0 sticky bg-gray-100 z-40"
                >
                  <input
                    type="checkbox"
                    className="mt-0.5"
                    checked={
                      selectedCatalogItems.size ===
                      Object.keys(currentDataGroup || {}).length
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedCatalogItems(
                          new Set(Object.keys(currentDataGroup || {})),
                        );
                      } else {
                        setSelectedCatalogItems(new Set());
                      }
                    }}
                  />
                </th>
                <th
                  scope="col"
                  className="px-6 p-3 top-0 sticky bg-gray-100 w-60 z-40"
                >
                  File name
                </th>
                <th
                  scope="col"
                  className="text-left px-6 py-3 top-0 sticky bg-gray-100 z-40"
                >
                  <div className="flex items-center gap-1">
                    <div
                      className={`items-center text-xl h-6 flex justify-center transition-all cursor-pointer py-1 px-2 rounded-md ${isGloballyExpanded ? "text-grey" : "text-grey"}`}
                      onClick={() => {
                        setIsGloballyExpanded((state: any) => !state);
                      }}
                    >
                      {isGloballyExpanded ? (
                        <FaMinimize />
                      ) : (
                        <FaExpandArrowsAlt />
                      )}
                    </div>
                    <span>Tags</span>
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 w-20 top-0 sticky bg-gray-100 z-40"
                >
                  Actions
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 w-20 top-0 sticky bg-gray-100 z-40"
                ></th>
                <th className="text-left w-24 px-6 py-3 top-0 sticky bg-gray-100 z-40">
                  Evidence
                </th>
              </tr>
            </thead>
            <tbody className="">
              {sortedEntries.map(([docKey, tags], index) => {
                const isExpanded =
                  isGloballyExpanded || expandedFiles.has(docKey);
                const isActionsOpened = docKey === actionsFile;
                const { duplicate } = getDuplicateParent(docKey);
                return (
                  <tr className="bg-white border-b" key={`${docKey}-${index}`}>
                    <td className="w-1">
                      <div className="justify-center items-center flex">
                        <input
                          type="checkbox"
                          checked={selectedCatalogItems.has(docKey)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              selectedCatalogItems.add(docKey);
                            } else {
                              selectedCatalogItems.delete(docKey);
                            }
                            setSelectedCatalogItems(
                              new Set(selectedCatalogItems),
                            );
                          }}
                        />
                      </div>
                    </td>
                    <td className="px-6 py-4 text-sm break-all text-gray-700">
                      <div className="flex items-center gap-1">
                        <div className="w-60">{docKey}</div>
                        {duplicate && (
                          <div
                            className={`ms-2 me-2 justify-center text-xl animate-pulse text-red-400 cursor-pointer`}
                            title={`This file is a duplicate of \'${duplicate}\'`}
                            onClick={() => {
                                setActionsFile("");
                                handleDatasetDelete(docKey)
                              }}>
                            <FontAwesomeIcon icon={faFileCircleExclamation} />
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4 flex gap-1">
                      <div
                        className={`flex gap-1 transition-all flex-wrap ${isExpanded ? "max-h-none overflow-auto" : "max-h-6 overflow-hidden"}`}
                      >
                        {tags &&
                          Object.entries(tags)
                            .filter(([key, value]) => {
                              return (
                                key !== "chunks" &&
                                key !== "file_directory" &&
                                !hiddenCategories?.includes(key) &&
                                value &&
                                Array.isArray(value) &&
                                value[0] &&
                                key in
                                  availableTags.sensitivity.tagger_params
                                    .tag_dict ===
                                  false &&
                                Object.keys(
                                  {
                                    ...availableTags.llm.tagger_params.tag_dict,
                                    ...ruleDict,
                                  } || {},
                                ).includes(key)
                              );
                            })
                            .map(([tagKey, value], index) => {
                              let processedValue;

                              if (Array.isArray(value)) {
                                // value being passed here is an array with a single element
                                if (
                                  typeof value[0] === "string" &&
                                  value[0].startsWith("[") &&
                                  value[0].endsWith("]")
                                ) {
                                  try {
                                    const correctedString = value[0]
                                      .replace(/'/g, '"')
                                      .replace(/(\w)"(\w)/g, "$1'$2"); // revert double quotes back to single quotes if they occur within words
                                    processedValue =
                                      JSON.parse(correctedString);
                                  } catch (error) {
                                    console.error(
                                      "Error parsing the array-like string:",
                                      error,
                                      "Original value:",
                                      value[0],
                                    );
                                    processedValue = [value[0]];
                                  }
                                } else {
                                  processedValue = [value[0]];
                                }
                              } else if (typeof value === "string") {
                                const trimmedValue = value.trim();
                                if (
                                  trimmedValue.startsWith("[") &&
                                  trimmedValue.endsWith("]")
                                ) {
                                  try {
                                    processedValue = JSON.parse(trimmedValue); // parse a list-like string
                                  } catch (error) {
                                    console.error(
                                      "Error parsing value:",
                                      error,
                                    );
                                    processedValue = [trimmedValue]; // fallback to original string if parsing fails
                                  }
                                }
                              }
                              return processedValue.map(
                                (val: string, idx: number) => (
                                  <div
                                    className="text-xs py-1 px-2 rounded-md"
                                    key={`${docKey}-${tagKey}-${val}-${idx}`}
                                    style={{
                                      backgroundColor:
                                        stringToColor(tagKey, "40") ||
                                        "#80808028",
                                      cursor:
                                        docKey.includes(".pdf") ||
                                        docKey.includes(".docx")
                                          ? "pointer"
                                          : "auto",
                                    }}
                                    onClick={async () => {
                                      if (
                                        !docKey.includes(".pdf") &&
                                        !docKey.includes(".docx")
                                      )
                                        return;

                                      if (!currentDataGroup) return;
                                      const response =
                                        await loadDocumentInfo(docKey);

                                      if (
                                        response.file_name.endsWith(".pdf") ||
                                        response.file_name.endsWith(".docx")
                                      ) {
                                        setPdf(response.file_url.toString());
                                        setShowFileOnPage("standard");
                                        const searchEvidence = Object.values(
                                          tags["chunks"],
                                        )
                                          .filter((chunk) => chunk[tagKey])
                                          .map(
                                            (chunk) => chunk[tagKey].evidence,
                                          );
                                        setPdfSearch(
                                          JSON.stringify(
                                            [...new Set(searchEvidence)].filter(
                                              Boolean,
                                            ),
                                          ),
                                        );
                                      } else {
                                        window.open(
                                          response.file_url,
                                          "_blank",
                                        );
                                        setActionsFile("");
                                      }
                                    }}
                                  >
                                    <span className="font-bold text-black">
                                      {tagKey}:
                                    </span>{" "}
                                    {val}
                                  </div>
                                ),
                              );
                            })
                            .flat()}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex justify-center relative">
                        <BsThreeDotsVertical
                          className="cursor-pointer"
                          onClick={() => {
                            setActionsFile(isActionsOpened ? "" : docKey);
                          }}
                        />
                        {isActionsOpened && (
                          <div className="absolute z-30 select-none top-[calc(100%_+_8px)] w-40 bg-white border rounded-md shadow-md flex flex-col">
                            <div
                              className="fixed inset-0 z-20"
                              onClick={() => setActionsFile("")}
                            ></div>
                            <div
                              className="cursor-pointer z-30 border-b border-b-gray-100 px-3 py-3 text-gray-600 hover:bg-gray-100"
                              onClick={() => openFile(docKey)}
                            >
                              Open
                            </div>
                            <div
                              className="cursor-pointer z-30 border-b border-b-gray-100 px-3 py-3 text-gray-600 hover:bg-gray-100"
                              onClick={() => downloadFile(docKey)}
                            >
                              Download
                            </div>
                            <PermissionGuard scope="catalogs" level="canEdit">
                              <div
                                className="cursor-pointer  z-30 border-b border-b-gray-100 px-3 py-3 text-gray-600 hover:bg-gray-100"
                                onClick={() => {
                                  setActionsFile("");
                                  handleDatasetDelete(docKey);
                                }}
                              >
                                Delete
                              </div>
                            </PermissionGuard>
                          </div>
                        )}
                      </div>
                    </td>
                    <th scope="col" className="text-left px-6 py-3 top-0  z-40">
                      <div className="flex items-center gap-1">
                        <div
                          className={`items-center text-xl h-6 flex justify-center transition-all cursor-pointer py-1 px-2 rounded-md ${isGloballyExpanded ? "text-grey" : "text-grey"}`}
                          onClick={() => {
                            if (isExpanded) {
                              expandedFiles.delete(docKey);
                            } else {
                              expandedFiles.add(docKey);
                            }
                            setExpandedFiles(new Set(expandedFiles));
                          }}
                        >
                          {isExpanded ? <FaMinimize /> : <FaExpandArrowsAlt />}
                        </div>
                      </div>
                    </th>
                    <td className="px-6 py-4">
                      <button
                        className="px-4 py-2 text-primary border-2 border-primary  rounded-md whitespace-nowrap"
                        onClick={() => onEvidenceClick(docKey)}
                      >
                        See evidence
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div className="h-full shrink-0 grow-0 flex flex-col bg-zinc-100 rounded-md overflow-hidden justify-center items-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-primary"></div>
            <p className="mt-4 text-lg font-semibold">
              Loading {usedCatalog}...
            </p>
          </div>
        )}
      </div>
      <div className="bg-white shadow-md w-full  rounded-b-md">
        <Paginator
          onNewPage={(newPage) => setCurrentPage(newPage)}
          currentPage={currentPage}
          onNewPageSize={(newPageSize) => {
            setCurrentPage(1);
            setPageSize(newPageSize);
          }}
          pageSize={pageSize}
          numberOfItems={Object.keys(currentDataGroup || {}).length}
        />
      </div>
    </div>
  );
};

export default CatalogTable;
