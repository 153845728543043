import TaggingStudio from "./TaggingStudio";
import { useContext, useState, useRef, useEffect } from "react";
import { parse } from "csv-parse/browser/esm/sync";
import {
  DataContext,
  defaultCurrentTag,
} from "../../../../../context/DataContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TagContext } from "../../../../../context/TagContext";
import Tags from "../DataCatalog/DataCatalogComponents/Tags/Tags";
import { mergeTagWithDefaults } from "../../../../utilities/functions/utils";
import AutoCreateTag from "./AutoCreateTag";
import AddTaggingRules from "../TaggingRules/TaggingRules";
import TagEditor from "./TagEditor";
import { COLOURS } from "../../../../../assets/colours";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import TabComponent from "./Tabs.js";
import { useAtom } from "jotai";
import { selectedTagKeysAtom } from "../../../../../atoms";
import { toast } from "../../../../utilities/Toast";
import { uploadTags } from "../../../../utilities/functions/apiCalls";
import { updateParentLabelObj } from "../../../../utilities/functions/utils";
import { PermissionGuard } from "../../../../utilities/PermissionGuard";

export default function AddNewTag() {
  const [currentValue, setCurrentValue] = useState("");
  const { currentTag, setCurrentTag, availableTags, setAvailableTags } =
    useContext(DataContext);
  const { saveTag, defaultTagTypes, activeTab, setActiveTab } =
    useContext(TagContext);
  const [showTaggingStudio, setShowTaggingStudio] = useState(false);
  const [previousExamples, setPreviousExamples] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [error, setError] = useState("");
  const fileInputRef = useRef(null);
  const [isTagLibraryCollapsed, setIsTagLibraryCollapsed] = useState(true);
  const tagDict = {
    ...availableTags.llm.tagger_params.tag_dict,
    ...availableTags.sensitivity.tagger_params.tag_dict,
  };

  const { usedCatalog } = useContext(DataContext);

  const isTagAvailable = tagDict.hasOwnProperty(currentTag.name);

  const [, setSelectedTagKeys] = useAtom(selectedTagKeysAtom);
  const options = Object.keys(tagDict).map((key) => ({
    value: key,
    label: key,
  }));

  const tagCount = availableTags
    ? Object.keys({
        ...availableTags.llm.tagger_params.tag_dict,
        ...availableTags.sensitivity.tagger_params.tag_dict,
      }).length
    : 0;

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      setError("No file selected.");
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      const content = e.target.result;
      try {
        const records = parse(content, {
          columns: true,
          skip_empty_lines: true,
        });

        const requiredColumns = [
          "Tag Name",
          "Type of Tag",
          "Description",
          "Output constraint type",
          "Output constraint",
          "Output type",
          "Tag only at the document level?",
          "Examples",
        ];
        const actualColumns = Object.keys(records[0]);
        if (
          requiredColumns.length !== actualColumns.length ||
          !requiredColumns.every((col) => actualColumns.includes(col))
        ) {
          setError("CSV does not have the required columns.");
          return;
        }

        // Value validation
        const isValid = records.every((record) => {
          const typeOfTagValid = ["Sensitivity", "Classification"].includes(
            record["Type of Tag"],
          );
          const expectingAValid = ["Word", "Number", "Date"].includes(
            record["Output type"],
          );
          const applyTagOnDocumentLevelValid = ["true", "false"].includes(
            record["Tag only at the document level?"],
          );

          return (
            typeOfTagValid && expectingAValid && applyTagOnDocumentLevelValid
          );
        });

        if (!isValid) {
          setError("One or more rows have invalid data.");
          return;
        }

        processTags(records);
        toast.info({
          title: "Tags processing",
          description: "File processed successfully.",
        });
      } catch (error) {
        setError("Error processing file: " + error.message);
      }
    };
    reader.readAsText(file);
    setError("");
    event.target.value = null;
  };

  const handleDownload = () => {
    const data = [
      [
        "Tag Name",
        "Type of Tag",
        "Description",
        "Output constraint type",
        "Output constraint",
        "Output type",
        "Tag only at the document level?",
        "Examples",
      ],
      [
        "medical_field",
        "Classification",
        "is the content related to the medical field",
        "yesNo",
        JSON.stringify(["Yes", "No"]).replace(/"/g, '""'),
        "Word",
        "false",
        JSON.stringify([
          { evidence: "some text showing no evidence", value: "No" },
          { evidence: "some text showing yes evidence", value: "Yes" },
        ]).replace(/"/g, '""'),
      ],
    ];

    function arrayToCSV(data) {
      return data
        .map((row) => row.map((cell) => `"${cell}"`).join(","))
        .join("\n");
    }

    const csvContent =
      "data:text/csv;charset=utf-8," + encodeURIComponent(arrayToCSV(data));

    const link = document.createElement("a");
    link.setAttribute("href", csvContent);
    link.setAttribute("download", "tag-template.csv");
    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);
  };

  const processTags = async (tags) => {
    const result = {};
    let updatedTags = { ...availableTags };

    for (const tagData of tags) {
      let examples = [];
      let outputConstraint = null;

      try {
        examples = JSON.parse(tagData["Examples"]);
      } catch (error) {
        console.error(
          `Error parsing examples for tag ${tagData["Tag Name"]}: ${error.message}`,
        );
      }

      outputConstraint = tagData["Output constraint"].split(",");

      const tag = {
        name: tagData["Tag Name"].replace(/,/g, ""),
        tagType: tagData["Type of Tag"],
        description: tagData["Description"],
        availableValues: outputConstraint,
        max_words: 1,
        option: tagData["Output constraint type"],
        allow_other_values: false,
        is_document_level:
          tagData["Tag only at the document level?"] === "true",
        type: tagData["Output type"],
        examples: examples,
      };

      if (tag.name.includes(",")) {
        toast.warning({
          title: "Warning",
          description: `Tag name "${tag.name}" cannot contain a comma. Commas were taken out of the tag name.`,
        });
      }

      const mergedTag = mergeTagWithDefaults(tag, defaultCurrentTag);

      updatedTags = updateParentLabelObj(updatedTags, mergedTag);

      const filename = tagData["Filename"] || "default";
      if (!result[filename]) {
        result[filename] = [];
      }
      result[filename].push(mergedTag);
    }

    try {
      await uploadTags(updatedTags, usedCatalog);
      toast.success({
        title: "Success",
        description: "All tags processed and uploaded successfully!",
      });
    } catch (error) {
      console.error(`Error uploading tags: ${error.message}`);
      toast.error({
        title: "Error",
        description: `Error uploading tags: ${error.message}`,
      });
    }

    setAvailableTags(updatedTags);
  };

  useEffect(() => {
    return () => setCurrentTag({ ...defaultCurrentTag });
  }, []);

  const toggleTaggingStudio = () => {
    const mergedTag = mergeTagWithDefaults(currentTag, defaultCurrentTag);
    setCurrentTag({ ...mergedTag });
    setActiveTab(1);
  };

  const clearCurrentTag = () => {
    setCurrentTag({ ...defaultCurrentTag });
    setPreviousExamples(null);
    setShowTooltip(false);
  };

  const handleAddValue = (e) => {
    if (e.key === "Enter" && currentValue.trim()) {
      setCurrentTag((prevTag) => ({
        ...prevTag,
        availableValues: [currentValue, ...(prevTag?.availableValues || [])],
      }));
      setCurrentValue("");
    }
  };

  const handleDeleteValue = (value) => {
    setCurrentTag((prevTag) => ({
      ...prevTag,
      availableValues: prevTag.availableValues.filter((item) => item !== value),
    }));
  };

  const handleChangeOption = (option) => {
    switch (option) {
      case "yesNo":
        setCurrentTag((prevTag) => ({
          ...prevTag,
          availableValues: ["Yes", "No"],
          max_words: 1,
          allow_other_values: false,
          reference_file: "",
          option: "yesNo",
        }));
        break;
      case "fileUpload":
        setCurrentTag((prevTag) => ({
          ...prevTag,
          availableValues: ["Yes", "No"],
          max_words: 1,
          allow_other_values: false,
          option: "fileUpload",
        }));
        break;
      case "aiGenerated":
        setCurrentTag((prevTag) => ({
          ...prevTag,
          availableValues: [],
          allow_other_values: true,
          reference_file: "",
          max_words: 2,
          option: "aiGenerated",
        }));
        break;
      case "custom":
        setCurrentTag((prevTag) => ({
          ...prevTag,
          availableValues: prevTag.availableValues,
          allow_other_values: false,
          reference_file: "",
          option: "custom",
        }));
        break;
      default:
        break;
    }
  };

  const changeType = (type) => {
    setCurrentTag((prevTag) => ({
      ...prevTag,
      type: type || "word",
    }));
  };

  const changeIsDocumentLevel = (isDocumentLevel) => {
    setCurrentTag((prevTag) => ({
      ...prevTag,
      is_document_level: isDocumentLevel,
    }));
  };

  const handleTagTypeChange = (type) => {
    setCurrentTag((prevTag) => {
      const newTag = { ...prevTag };
      newTag.tagType = newTag.tagType === type ? "" : type;
      if (type === defaultTagTypes["sensitivity"]) {
        newTag.risk_level = newTag.risk_level || "High";
      }
      return newTag;
    });
  };

  const handleRiskLevelChange = (level) => {
    setCurrentTag((prevTag) => ({
      ...prevTag,
      risk_level: level,
    }));
  };

  const addExamplePair = () => {

    const updatedExamples = currentTag.examples
      ? [...currentTag.examples, { evidence: "", value: "" }]
      : [{ evidence: "", value: "" }];

    setCurrentTag({
      ...currentTag,
      examples: updatedExamples,
    });
  };

  const addNegExamplePair = () => {

    const updatedNegExamples = currentTag.neg_examples
      ? [...currentTag.neg_examples, { evidence: "", value: "" }]
      : [{ evidence: "", value: "" }];

    setCurrentTag({
      ...currentTag,
      neg_examples: updatedNegExamples,
    });
  };

  const updateExample = (index, field, value, exampleType = 'examples') => {
    const newExamples = exampleType === 'examples' 
      ? [...currentTag.examples]
      : [...currentTag.neg_examples];
  
    newExamples[index] = {
      ...newExamples[index],
      [field]: value,
    };
  
    setCurrentTag({
      ...currentTag,
      [exampleType]: newExamples,
    });
  };

  const deleteExamplePair = (index) => {
    const newExamples = currentTag.examples.filter((_, i) => i !== index);
    setCurrentTag({
      ...currentTag,
      examples: newExamples,
    });
  };

  const deleteNegExamplePair = (index) => {
    const newExamples = currentTag.neg_examples.filter((_, i) => i !== index);
    setCurrentTag({
      ...currentTag,
      neg_examples: newExamples,
    });
  };
  const isExistingTag = currentTag.name in tagDict;

  return (
    <div className="relative flex w-[98vw] overflow-hidden h-[90vh] transition-all duration-300">
      <div className={`flex  flex-row gap-4 ${!showTaggingStudio && "w-full"}`}>
        <div
          className={`relative bg-[rgb(231, 237, 244)] bg-white rounded-md flex flex-col transition-all duration-300 text-sm ${
            showTaggingStudio ? "pb-8" : "max-w-full pb-4"
          }`}
        >
          {isTagLibraryCollapsed ? (
            <>
              <div
                className="flex flex-row w-[5vw] justify-between p-3 items-center "
                style={{ backgroundColor: COLOURS["HeaderBackground"] }}
              >
                <FontAwesomeIcon
                  icon={faBook}
                  className="text-grey mr-2 text-xl"
                />

                <button
                  onClick={() => setIsTagLibraryCollapsed(false)}
                  className="text-grey p-2 rounded-md border-grey border-2"
                >
                  {">"}
                </button>
              </div>
              <div className="flex flex-col items-center justify-center">
                <span className="text-grey font-bold items-center justify-center flex flex-row mt-4 text-xl">
                  {tagCount}
                </span>
                <span className="text-md">tags </span>
              </div>
            </>
          ) : (
            <div className="w-[25vw] flex flex-col rounded-t-md overflow-auto">
              <Tags
                title="Tag library"
                tagTypes={["llm", "sensitivity"]}
                isTagLibraryCollapsed={isTagLibraryCollapsed}
                setIsTagLibraryCollapsed={setIsTagLibraryCollapsed}
              />
            </div>
          )}
        </div>
        {activeTab === 1 && (
          <div
            className={"relative bg-white rounded-md flex flex-col text-sm "}
          >
            <div className="w-[25vw]">
              <header
                className=" text-grey p-3 rounded-t-md flex justify-between items-center"
                style={{ backgroundColor: COLOURS["HeaderBackground"] }}
              >
                <div className="w-[50%]">
                  <Select
                    options={options}
                    placeholder="Select a tag"
                    isSearchable={true}
                    value={options.find(
                      (option) => option.value === currentTag.name,
                    )}
                    onChange={(e) => {
                      setCurrentTag(
                        {
                          ...availableTags.llm.tagger_params.tag_dict,
                          ...availableTags.sensitivity.tagger_params.tag_dict,
                        }[e.value],
                      );
                    }}
                  />
                </div>
              </header>
              <TagEditor
                currentTag={currentTag}
                setCurrentTag={setCurrentTag}
                addExamplePair={addExamplePair}
                addNegExamplePair={addNegExamplePair}
                updateExample={updateExample}
                deleteExamplePair={deleteExamplePair}
                deleteNegExamplePair={deleteNegExamplePair}
                toggleTaggingStudio={toggleTaggingStudio}
                handleChangeOption={handleChangeOption}
                handleAddValue={handleAddValue}
                handleDeleteValue={handleDeleteValue}
                currentValue={currentValue}
                setCurrentValue={setCurrentValue}
                handleTagTypeChange={handleTagTypeChange}
                handleRiskLevelChange={handleRiskLevelChange}
                changeType={changeType}
                changeIsDocumentLevel={changeIsDocumentLevel}
                showTooltip={showTooltip}
                setShowTooltip={setShowTooltip}
                activeTab={activeTab}
                options={options}
              />
              <div className="flex flex-row w-full justify-between p-4 absolute bottom-0 bg-white">
                <button
                  className={`text-lg py-2 rounded-md font-bold w-[160px] border-2 ${
                    isTagAvailable
                      ? "text-primary border-primary"
                      : "text-gray-400 border-gray-400"
                  }`}
                  onClick={() => saveTag(currentTag)}
                  disabled={!isTagAvailable}
                >
                  Update tag
                </button>
              </div>
            </div>
          </div>
        )}
        <div
          className={`h-full bg-white rounded-md overflow-auto flex flex-col text-sm w-full`}
        >
          <div className="h-full overflow-hidden hide-scrollbar flex flex-col mb-5">
            <TabComponent
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              fileInputRef={fileInputRef}
              handleFileChange={handleFileChange}
            />
            {error && <p className="text-red-500">{error}</p>}
            {activeTab === 0 ? (
              <TagEditor
                currentTag={currentTag}
                setCurrentTag={setCurrentTag}
                addExamplePair={addExamplePair}
                addNegExamplePair={addNegExamplePair}
                updateExample={updateExample}
                deleteExamplePair={deleteExamplePair}
                deleteNegExamplePair={deleteNegExamplePair}
                toggleTaggingStudio={toggleTaggingStudio}
                handleChangeOption={handleChangeOption}
                handleAddValue={handleAddValue}
                handleDeleteValue={handleDeleteValue}
                currentValue={currentValue}
                setCurrentValue={setCurrentValue}
                handleTagTypeChange={handleTagTypeChange}
                handleRiskLevelChange={handleRiskLevelChange}
                changeType={changeType}
                changeIsDocumentLevel={changeIsDocumentLevel}
                showTooltip={showTooltip}
                setShowTooltip={setShowTooltip}
                activeTab={activeTab}
                options={options}
              />
            ) : activeTab === 1 ? (
              <TaggingStudio
                previousExamples={previousExamples}
                setPreviousExamples={setPreviousExamples}
              />
            ) : activeTab === 2 ? (
              <AutoCreateTag />
            ) : activeTab === 3 ? (
              <AddTaggingRules />
            ) : (
              <div className="flex flex-col w-full h-full relative p-5 z-40 bg-white rounded">
                <div className="mb-4">
                  <h1 className="font-semibold text-2xl pb-4">Import Tags</h1>

                  <p className="text-lg">
                    Please use the template csv to import your tags
                  </p>
                </div>
                <div className="flex gap-4 max-w-[50%]">
                  <button
                    className="text-lg p-2 rounded-md text-primary border-2 whitespace-nowrap border-primary font-bold w-[40%]"
                    onClick={() => fileInputRef.current?.click()}
                  >
                    Upload Tags
                  </button>
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    className="hidden"
                  />
                  <button
                    onClick={handleDownload}
                    className="text-lg p-2 rounded-md bg-primary border-2 border-primary whitespace-nowrap text-white font-bold w-[40%]"
                  >
                    Download Template
                  </button>
                </div>
              </div>
            )}
          </div>
          <PermissionGuard scope="tags" level="canEdit">
            {!showTaggingStudio && (
              <div className="flex w-full text-base items-end text-right flex-col">
                <span className="w-full bg-grey opacity-15 h-[0.1vh]"></span>
                <div className="flex flex-row w-full justify-start">
                  {activeTab === 0 && (
                    <>
                      <button
                        className="border-primary border-2 w-1/12 text-primary m-4 p-2 rounded-md"
                        onClick={() => {
                          clearCurrentTag();
                        }}
                      >
                        New tag
                      </button>

                      <button
                        className="bg-primary w-2/12 text-white m-4 p-2 rounded-md"
                        disabled={
                          !(
                            currentTag.name &&
                            currentTag.tagType &&
                            currentTag.description
                          )
                        }
                        onClick={async () => {
                          const result = await saveTag(currentTag);
                          if (!isExistingTag && result) {
                            setSelectedTagKeys([currentTag.name]);
                          }
                        }}
                      >
                        Save Tag
                      </button>

                      <button
                        className={`text-primary border-primary border-2 w-1/12 m-4 p-2 rounded-md ${
                          tagDict &&
                          !Object.keys(tagDict).includes(currentTag.name) &&
                          "cursor-not-allowed opacity-50 disabled"
                        }`}
                        onClick={() => {
                          setActiveTab(1);
                        }}
                        title={
                          tagDict &&
                          !Object.keys(tagDict).includes(currentTag.name) &&
                          "Please select or save a tag to test it"
                        }
                        disabled={
                          tagDict &&
                          !Object.keys(tagDict).includes(currentTag.name)
                        }
                      >
                        Test tag
                      </button>
                    </>
                  )}
                </div>
              </div>
            )}
          </PermissionGuard>
        </div>
      </div>
    </div>
  );
}
