import { useContext, useMemo, useState } from "react";
import {
  useCatalogNames,
  useCreateCatalogMutation,
} from "../../../api/queryHooks";
import { DataContext } from "../../../context/DataContext";
import Auth from "../../../auth/AuthProvider";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { toast } from "../Toast";
import useCatalogData from "../../pages/Home/HomeComponents/DataCatalog/useCatalogData";
import { ENDPOINTS } from "../../../api/endpoints";
import { sendRequest } from "../functions/api";
import { selectedCatalogItemsAtom } from "../../../atoms";
import { RxCross2 } from "react-icons/rx";
import { useAtom } from "jotai";
import DataGraph from "../../pages/Home/HomeComponents/DataCatalog/DataCatalogComponents/DataGraph/DataGraph";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FaChartBar, FaFilter, FaPencilAlt, FaTable } from "react-icons/fa";
import { MdCheck } from "react-icons/md";
import { PermissionGuard } from "../PermissionGuard";
import { useCatalogContext } from "../../../context/CatalogContext";

const styleForButton =
  "ml-4 px-4 py-2 text-center rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50 transition duration-300 ease-in-out text-primary border-2 border-primary font-bold whitespace-nowrap items-center flex gap-1";

export default function SearchBar() {
  const {
    setSearchTerm,
    usedCatalog,
    handleCatalogChange,
    handleCatalogRename,
    handleEvidenceButtonClick,
    handleMultipleDelete,
    setShowScreen,
    preferences,
    setCurrentTag,
    currentDataGroup,
    selectedFilters,
    setView,
    setShowConnectData,
    setCatalogGetsRenamed,
    catalogGetsRenamed,
    setUsedCatalog,
    catalogGetsCreated,
    setCatalogGetsCreated,
    fetchInitialCatalog,
    fetchInitialTaggerList,
  } = useContext(DataContext);
  const { quarantineFiles } = useCatalogData();
  const { isLoading, data: catalogNames = [], refetch } = useCatalogNames();
  const [isCatalogNameBeingEdited, setIsCatalogNameBeingEdited] =
    useState(false);
  const [oldCatalogName, setOldCatalogName] = useState("");
  const [newCatalogName, setNewCatalogName] = useState("");
  const [showAddOptions] = useState(false);
  const [showDistributionModal, setShowDistributionModal] = useState(false);
  const [selectedTagCatalog, setSelectedTagCatalog] = useState("");
  const [selectedCatalogItems] = useAtom(selectedCatalogItemsAtom);
  const [scanDocumentsLoading, setScanDocumentsLoading] = useState(false);

  const createCatalogMutation = useCreateCatalogMutation();

  const selectValue = useMemo(() => {
    return catalogNames.includes(usedCatalog) ? usedCatalog : "";
  }, [usedCatalog, catalogNames]);

  const { setShowAdvancedTagFilters } = useCatalogContext();

  const scanDocuments = async () => {
    const entries = [];

    const selectedFiles = Array.from(selectedCatalogItems);
    const dataSnapShot = { ...currentDataGroup };

    // Iterate through each file and prepare entries
    for (const file_name of Object.keys(dataSnapShot)) {
      if (selectedFiles.length === 0 || selectedFiles.includes(file_name)) {
        const catalogItem = dataSnapShot[file_name];
        const sendChunkObject = {
          data_store: JSON.stringify({
            ...preferences.webapp_profile.DATA_STORES[
              catalogItem.data_store_name
                ? catalogItem.data_store_name
                : catalogItem.storage_type
            ],
            path: `${catalogItem.file_directory}/${file_name}`,
          }),
        };

        entries.push(sendChunkObject);
      }
    }

    const creds = (await Auth.currentAuthenticatedUser()).username;
    const requestBody = {
      entries,
      [preferences.system.API_USERNAME_KEYWORD]: creds,
      preferences: JSON.stringify(preferences),
      catalog_name: usedCatalog,
    };
    setScanDocumentsLoading(true);
    await sendRequest(requestBody, ENDPOINTS["scan_documents"]).then(
      () => {
        fetchInitialCatalog(usedCatalog)
        setScanDocumentsLoading(false);
      }
    );
  };

  const handleCreateNewCatalog = async () => {
    setCatalogGetsCreated(false);

    const createdCatalogName = newCatalogName.trim();

    if (!createdCatalogName) {
      toast.error({
        title: "Error",
        description: "Catalog name and tag catalog are required.",
      });
      return;
    }

    createCatalogMutation.mutate(
      { catalogName: newCatalogName, tagCatalog: selectedTagCatalog },
      {
        onSuccess: async () => {
          setCatalogGetsRenamed(true);
          setUsedCatalog(createdCatalogName);
          setNewCatalogName("");
          setSelectedTagCatalog("");
          await fetchInitialCatalog(createdCatalogName);
          await fetchInitialTaggerList(createdCatalogName);
          toast.success({
            title: "Success",
            description: "Catalog created successfully!",
          });
          setCatalogGetsRenamed(false);
        },
        onError: () => {
          toast.error({
            title: "Error",
            description: "Failed to create catalog.",
          });
          setCatalogGetsRenamed(false);
        },
      },
    );
  };

  const handleRenameCatalog = async () => {
    if (newCatalogName.trim() === "") {
      toast.error({
        title: "Error",
        description: "Catalog name cannot be empty",
      });
      return;
    }

    setCatalogGetsRenamed(true);

    toast.info({
      title: "Info",
      description: "Your catalog is being renamed, please wait",
    });

    try {
      await handleCatalogRename(oldCatalogName, newCatalogName);
      await refetch();
      toast.success({
        title: "Success",
        description: "Your catalog was successfully renamed!",
      });
    } catch (error) {
      toast.error({
        title: "Error",
        description: "Failed to rename catalog. Please try again.",
      });
    } finally {
      setCatalogGetsRenamed(false);
      setIsCatalogNameBeingEdited(false);
      setOldCatalogName("");
      setNewCatalogName("");
    }
  };

  return (
    <div className="w-full flex flex-col rounded-t-md bg-white">
      {catalogGetsCreated && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 ">
          <div className="bg-white p-6 rounded-lg shadow-xl w-[30vw] ">
            <h2 className="text-xl font-bold mb-4">Create New Catalog</h2>
            <input
              type="text"
              placeholder="New Catalog Name"
              value={newCatalogName}
              onChange={(e) => setNewCatalogName(e.target.value)}
              className="w-full p-2 mb-4 border rounded"
            />
            <select
              value={selectedTagCatalog}
              onChange={(e) => setSelectedTagCatalog(e.target.value)}
              className="w-full p-2 mb-4 border rounded"
            >
              <option value={null}>Attach no default tags</option>
              {catalogNames.map((catalog, index) => (
                <option key={index} value={catalog}>
                  {catalog}
                </option>
              ))}
            </select>
            <div className="flex justify-end">
              <button
                onClick={() => setCatalogGetsCreated(false)}
                className="mr-2 px-4 py-2 bg-gray-200 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleCreateNewCatalog}
                className="px-4 py-2 bg-primary text-white rounded"
              >
                Create
              </button>
            </div>
          </div>
        </div>
      )}
      {showDistributionModal && (
        <div
          onClick={() => {
            setShowDistributionModal(false);
          }}
          className="flex justify-center items-center fixed inset-0 bg-black bg-opacity-40 backdrop-blur-sm z-[9999] p-4"
        >
          <DataGraph
            selectedFilters={selectedFilters}
            onClose={() => setShowDistributionModal(false)}
          />
        </div>
      )}
      <div className="flex w-full p-5 items-center">
        <div className="flex flex-row w-full">
          <div className="relative">
            {isLoading || catalogGetsRenamed ? (
              <div className="  px-2 py-1 rounded-md">Loading...</div>
            ) : isCatalogNameBeingEdited ? (
              <input
                className="p-2 ml-2 rounded-md border outline-none text-black"
                value={newCatalogName}
                onChange={(e) => setNewCatalogName(e.target.value)}
              />
            ) : (
              <select
                onChange={handleCatalogChange}
                value={selectValue}
                className="p-2 font-bold  rounded-md focus:outline-none text-primary border-2 border-primary"
              >
                <option value="" disabled>
                  Select a catalog
                </option>
                {catalogNames.map((catalog, index) => (
                  <option key={index} value={catalog}>
                    {catalog} {}
                  </option>
                ))}
                <PermissionGuard scope="catalogs" level="canEdit">
                  <option value="newCatalog">Create New Catalog</option>
                </PermissionGuard>
              </select>
            )}

            {isCatalogNameBeingEdited && (
              <div className="flex items-center">
                <button
                  onClick={handleRenameCatalog}
                  className=" bg-deasieTurquoise p-2 rounded-md"
                >
                  <MdCheck />
                </button>
                <button
                  onClick={() => {
                    setIsCatalogNameBeingEdited(false);
                    setOldCatalogName("");
                    setNewCatalogName("");
                  }}
                  className=" bg-rose-500 p-2 rounded-md"
                >
                  <RxCross2 />
                </button>
              </div>
            )}
          </div>
          <PermissionGuard scope="catalogs" level="canEdit">
            {!isCatalogNameBeingEdited && (
              <div className="h-full flex flex-row items-center gap-3 align-middle justify-center">
                <button
                  className="p-2 text-deasieBlack"
                  onClick={() => {
                    setIsCatalogNameBeingEdited(true);
                    setOldCatalogName(selectValue);
                    setNewCatalogName(selectValue);
                  }}
                  title="Rename Catalog"
                >
                  <FaPencilAlt />
                </button>
                <div className="flex flex-row items-center border-2 rounded-md px-2">
                  <p>
                    <span className="font-bold text-primary">
                      {currentDataGroup
                        ? Object.keys(currentDataGroup).length
                        : 0}
                    </span>
                    <span className="text-primary"> datasets</span>
                  </p>
                  <button
                    className="p-2 text-buttonGrey"
                    onClick={handleMultipleDelete}
                    title="Delete multiple datasets"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                  <button
                    className="p-2 text-buttonGrey"
                    onClick={() => {
                      const confirmation = window.confirm(
                        `Are you sure you want to quarantine ${Object.keys(currentDataGroup || {}).length} files?`,
                      );
                      if (!confirmation) return;
                      quarantineFiles();
                    }}
                    title="Quarantine multiple datasets"
                  >
                    <FontAwesomeIcon icon={faLock} />
                  </button>
                </div>
              </div>
            )}
          </PermissionGuard>
        </div>

        <div className="flex w-full justify-end">
          {/* <div
              className={`${styleForButton} bg-primary text-white cursor-pointer`}
              onClick={async () => {
                await scanDocuments();
              }}
            >
              {scanDocumentsLoading ? (
                <div className="flex items-center gap-2">
                  <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
                  <span>Scanning...</span>
                  </div>
                  ) : (
                    <div className="flex items-center gap-2">
                      <span>Scan for Duplicates</span>
                      </div>
                      )}
          </div> */}
          <div className="dropdown relative">
            <PermissionGuard scope="tags" level="canEdit">
              <div
                className={`${styleForButton} bg-primary text-white cursor-pointer`}
                //onClick={() => setShowAddOptions((prevState) => !prevState)}
                onClick={() => {
                  setShowScreen("addNewTag");
                  setCurrentTag({});
                }}
              >
                New Tag
              </div>
            </PermissionGuard>
            <div
              className={`transition-all duration-300 ease-in-out ${
                showAddOptions
                  ? "opacity-100 transform translate-y-0 "
                  : "opacity-0 transform -translate-y-4 pointer-events-none"
              } absolute mt-2 z-50 bg-white shadow-lg rounded w-full`}
            >
              <a
                className="block px-4 py-2 text-xl text-gray-700 hover:bg-gray-200 cursor-pointer"
                onClick={() => {
                  setShowScreen("addNewTag");
                  setCurrentTag({});
                }}
              >
                Manual
              </a>
              <PermissionGuard scope="tags" level="canEdit">
                <a
                  className="block px-4 py-2 text-xl text-gray-700 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    setShowScreen("autoCreateTag");
                  }}
                >
                  Auto create
                </a>
              </PermissionGuard>
            </div>
          </div>
          <PermissionGuard scope="tags" level="canEdit">
            <button
              className={styleForButton}
              onClick={() => {
                setView("options");
                setShowConnectData(true);
              }}
              title="Add Data"
            >
              Add Data
            </button>
          </PermissionGuard>
          <button
            className={styleForButton}
            onClick={() => setShowAdvancedTagFilters(true)}
            title="Filter Tags"
          >
            <FaFilter />
          </button>
          <button
            onClick={() =>
              handleEvidenceButtonClick(Object.keys(currentDataGroup || {}))
            }
            className={styleForButton}
          >
            <FaTable />
          </button>
          <button
            onClick={() => {
              setShowDistributionModal(true);
            }}
            className={styleForButton}
            title="Distribution"
          >
            <FaChartBar />
          </button>
        </div>
      </div>
      <div className="flex items-center bg-white dark:bg-secondary rounded-lg p-4 shadow-md hover:shadow-xl m-2 ">
        <i className="fas fa-search text-gray-500 dark:text-gray-400 mr-3 "></i>
        <input
          type="text"
          className="block w-full bg-transparent focus:outline-none"
          placeholder="Search for a document"
          onChange={(event) => {
            setSearchTerm(event.target.value);
          }}
        />
      </div>
    </div>
  );
}
