import { useContext, useMemo, useState } from "react";
import "./UseCaseLibrary.css";
import { COLOURS } from "../../../../../assets/colours";
import {
  faTrash,
  faCog,
  faComments,
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sendRequest } from "../../../../utilities/functions/api";
import { ENDPOINTS } from "../../../../../api/endpoints";
import Auth from "../../../../../auth/AuthProvider";
import { DataContext } from "../../../../../context/DataContext";
import { UsecaseContext } from "../../../../../context/UsecaseContext";
import { toast } from "../../../../utilities/Toast";
import { parseISO, format } from "date-fns";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  flexRender,
} from "@tanstack/react-table";
import { PermissionGuard } from "../../../../utilities/PermissionGuard";

export default function UseCaseLibraryMenu() {
  const {
    useCases,
    setShowScreen,
    setUsecaseSelected,
    setUseCases,
    preferences,
  } = useContext(DataContext);
  const { editUseCase, setUsecaseStage, setCurrentUseCase } =
    useContext(UsecaseContext);
  const [sorting, setSorting] = useState([]);
  const [filters, setFilters] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const data = useMemo(() => {
    return useCases.filter(
      (useCase) =>
        useCase.name.toLowerCase().includes(searchInput.toLowerCase()) ||
        useCase.description.toLowerCase().includes(searchInput.toLowerCase()) ||
        (useCase.vector_database || "Default")
          .toLowerCase()
          .includes(searchInput.toLowerCase()),
    );
  }, [useCases, searchInput]);

  const goToChat = (usecase) => {
    setUsecaseSelected(usecase);
    setShowScreen("chat");
  };

  const handleDelete = async (id) => {
    const userConfirmed = window.confirm(
      `Are you sure you want to delete usecase ${id}?`,
    );

    if (userConfirmed) {
      try {
        const authenticatedUser = await Auth.currentAuthenticatedUser();
        const deleteUsecase = useCases.find((item) => item.id === id);

        if (!deleteUsecase) {
          throw new Error("Usecase not found");
        }

        await sendRequest(
          {
            [preferences.system.API_USERNAME_KEYWORD]:
              authenticatedUser.username,
            usecase_name: id,
            usecase_files: Object.keys(deleteUsecase.usecase_catalog),
          },
          ENDPOINTS["delete_usecase"],
        );

        const filteredItems = useCases.filter((item) => item.id !== id);
        setUseCases(filteredItems);
        toast.success({
          title: "Success",
          description: "Your usecase has been successfully deleted",
        });
      } catch (error) {
        toast.error({
          title: "Error",
          description: `There was an error trying to delete the usecase: ${String(
            error,
          )}`,
        });
      }
    }
  };

  const goToUsecaseDefinition = () => {
    setUsecaseStage("usecase-definition");
    setCurrentUseCase(preferences.webapp_profile.BASE_USECASE);
  };

  const columns = useMemo(
    () => [
      {
        id: "id",
        accessorKey: "id",
        header: "ID",
        filterFn: "equals",
        enableSorting: true,
        cell: (info) => info.getValue(),
      },
      {
        id: "name",
        accessorKey: "name",
        header: "Name",
        filterFn: "includesString",
        enableSorting: true,
        enableFiltering: true,
        cell: (info) => info.getValue(),
      },
      {
        id: "description",
        accessorKey: "description",
        header: "Description",
        filterFn: "includesString",
        enableSorting: true,
        enableFiltering: true,
        cell: (info) => info.getValue(),
      },
      {
        id: "catalog_name",
        accessorFn: (d) =>
          d.catalog_name ? d.catalog_name : preferences.system.EXISTING_CATALOG,
        header: () => "Catalog",
        filterFn: "includesString",
        enableSorting: true,
        enableFiltering: true,
        cell: (info) => info.getValue(),
      },
      {
        id: "vector_database",
        accessorFn: (d) => (d.vector_database ? d.vector_database : "Default"),
        header: () => "Storage",
        filterFn: "includesString",
        enableSorting: true,
        enableFiltering: true,
        cell: (info) => info.getValue(),
      },
      {
        id: "metadata",
        accessorFn: (d) =>
          d.metadata && d.metadata.length
            ? Array.isArray(d.metadata)
              ? d.metadata.join(", ")
              : d.metadata
            : "No Metadata selected",
        header: () => "Metadata",
        filterFn: "includesString",
        enableSorting: true,
        enableFiltering: true,
        cell: (info) => info.getValue(),
      },
      {
        id: "usecase_catalog",
        accessorFn: (d) =>
          d.usecase_catalog ? Object.keys(d.usecase_catalog).length : 0,
        header: () => "# of Datasets",
        filterFn: "inNumberRange",
        enableSorting: true,
        enableFiltering: true,
        cell: (info) => info.getValue(),
      },
      {
        id: "date_created",
        accessorKey: "date_created",
        header: "Date Created",
        filterFn: "includesString",
        enableSorting: true,
        enableFiltering: true,
        cell: (info) => {
          try {
            const dateValue = info.getValue();
            const parsedDate = parseISO(dateValue);
            return format(parsedDate, "yyyy-MM-dd");
          } catch (error) {
            return "";
          }
        },
      },
      {
        id: "actions",
        enableSorting: false,
        enableFiltering: false,
        cell: ({ row }) => (
          <div className="flex space-x-2 justify-end items-center">
            <div className="flex space-x-2">
              <button
                onClick={() => goToChat(row.original)}
                disabled={!row.original.usecase_created}
                className="activate-api-btn bg-primary"
                title="Chat with data"
              >
                <FontAwesomeIcon
                  icon={faComments}
                  style={{ color: COLOURS["MainText"] }}
                />
              </button>
              <PermissionGuard scope="usecases" level="canEdit">
                <div className="flex space-x-2">
                  <button
                    onClick={() => editUseCase(row.original.id)}
                    className="edit-button-usecase"
                    title="Edit use case"
                  >
                    <FontAwesomeIcon
                      icon={faCog}
                      style={{ color: COLOURS["MainText"] }}
                    />
                  </button>
                  <button
                    onClick={() => handleDelete(row.original.id)}
                    className="delete-button"
                    title="Delete use case"
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      style={{ color: COLOURS["MainText"] }}
                    />
                  </button>
                </div>
              </PermissionGuard>
            </div>
          </div>
        ),
        disableFilters: true,
      },
    ],
    [preferences],
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      filters,
    },
    onSortingChange: setSorting,
    onFiltersChange: setFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <div className="w-full flex flex-col h-full">
      <div className="flex-none sticky bg-white top-0 z-10">
        <div className="flex justify-between px-6 py-4">
          <input
            type="text"
            className="form-input px-4 py-2 w-full sm:w-1/1 border rounded shadow"
            placeholder="Search use cases..."
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </div>
      </div>
      <table className="min-w-full divide-y divide-gray-200 relative">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.column.id}
                  className="px-6 bg-gray-50 sticky z-10 top-16 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  <div
                    onClick={header.column.getToggleSortingHandler()}
                    className="flex items-center cursor-pointer"
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                    <span className="ml-2">
                      {header.column.id !== "actions" &&
                        (header.column.getIsSorted() === "asc" ? (
                          <FontAwesomeIcon icon={faSortUp} />
                        ) : header.column.getIsSorted() === "desc" ? (
                          <FontAwesomeIcon icon={faSortDown} />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSort}
                            className="text-gray-400"
                          />
                        ))}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="bg-white divide-y divide-gray-200 overflow-auto">
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 max-w-xs overflow-ellipsis overflow-auto"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-4 flex justify-end">
        <PermissionGuard scope="usecases" level="canEdit">
          <button
            type="button"
            className="bg-primary addusecase-button text-white font-semibold py-2 rounded hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
            onClick={goToUsecaseDefinition}
          >
            Define data for usecase
          </button>
        </PermissionGuard>
      </div>
    </div>
  );
}
