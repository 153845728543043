import {
  useMemo,
  useContext,
  useEffect,
  useState,
  useRef,
  Fragment,
} from "react";
import { DataContext } from "../../../../../context/DataContext";
import {
  getTagRules,
  uploadTagRules,
} from "../../../../utilities/functions/apiCalls";
import { Listbox, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { toast } from "../../../../utilities/Toast";
import { PermissionGuard } from "../../../../utilities/PermissionGuard";

export default function AddTaggingRules() {
  const { usedCatalog, availableTags, setView, showConnectData } =
    useContext(DataContext);

  const [selectedTag, setSelectedTag] = useState("");
  const [bulkTag, setBulkTag] = useState("");
  const [tableData, setTableData] = useState([]);
  const [allRules, setAllRules] = useState({});
  const [filter, setFilter] = useState("");
  const [conditionFilter, setConditionFilter] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const dropdownRef = useRef(null);

  const disabledClass = selectedTag ? "" : "opacity-20 pointer-events-none";

  const tagAvailableValues = useMemo(() => {
    const tags = Object.values(availableTags).reduce(
      (acc, value) => ({
        ...acc,
        ...Object.entries(value.tagger_params?.tag_dict || {}).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: value.availableValues || [],
          }),
          {},
        ),
      }),
      {},
    );
    return tags;
  }, [availableTags]);

  useEffect(() => {
    const constructRuleTree = (rule, depth, ancestorTagsName) => {
      const parentRuleTree = {
        conditionValue: rule.conditionValue,
        tagName: rule.tagName,
        ancestorTagsName: ancestorTagsName.concat(rule.tagName),
        children: [],
        depth: depth,
        treeEnd: rule.conditionValue === "" ? true : false,
      };
      rule.children.forEach((childRule) => {
        const childRuleTree = constructRuleTree(
          childRule,
          depth + 1,
          ancestorTagsName.concat(rule.tagName),
        );
        childRuleTree.parentTag = parentRuleTree;
        parentRuleTree.children.push(childRuleTree);
      });
      return parentRuleTree;
    };

    const addDataToTableInit = (tableInit, rootRule) => {
      tableInit.push(rootRule);
      rootRule.children.forEach((childRule) => {
        addDataToTableInit(tableInit, childRule);
      });
    };

    const fetchTagRules = async () => {
      if (selectedTag === "") {
        return;
      }
      let tableInit = [];
      const tagRules = await getTagRules(usedCatalog);
      let rootRules = [];

      if (tagRules) {
        tagRules.forEach((rule) => {
          if (rule.tagName === selectedTag) {
            const ruleTree = constructRuleTree(rule, 0, []);
            rootRules.push(ruleTree);
          }
        });
        rootRules.forEach((rootRule) => {
          addDataToTableInit(tableInit, rootRule);
        });

        if (tableInit.length === 0) {
          const specificRules = allRules[selectedTag] || [];
          const initialValues = tagAvailableValues[selectedTag] || [];
          tableInit =
            specificRules.length > 0
              ? specificRules.map((rule) => ({
                  conditionValue: rule.conditions[0].value,
                  tagName: rule.conditions[0].tag,
                  ancestorTagsName: [rule.conditions[0].tag],
                  children: [],
                  depth: 0,
                }))
              : initialValues.map((value) => ({
                  conditionValue: value,
                  tagName: selectedTag,
                  ancestorTagsName: [selectedTag],
                  children: [],
                  depth: 0,
                }));
        }

        const filteredTableInit = tableInit.filter((row) => {
          return (
            typeof row.conditionValue === "string" &&
            row.conditionValue
              .toLowerCase()
              .includes(conditionFilter.toLowerCase())
          );
        });

        setTableData(filteredTableInit);
      }
    };

    fetchTagRules();
  }, [
    selectedTag,
    allRules,
    conditionFilter,
    tagAvailableValues,
    usedCatalog,
    availableTags,
  ]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleSkip = () => {
    if (selectedTag) {
      saveRules();
    }
    setView("tagSelection");
  };

  const addTagToRow = (parentTag, childTag, parentRowIndex) => {
    const updatedData = [...tableData];
    const parentRow = updatedData[parentRowIndex];
    if (!parentRow.ancestorTagsName.includes(childTag.name)) {
      const newChildren = [];
      if (childTag.availableValues.length > 0) {
        childTag.availableValues.forEach((value) => {
          newChildren.push({
            conditionValue: value,
            ancestorTagsName: parentTag.ancestorTagsName.concat(childTag.name),
            tagName: childTag.name,
            children: [],
            parentTag: parentTag,
            depth: parentTag.depth + 1,
            treeEnd: false,
          });
        });
      } else {
        newChildren.push({
          conditionValue: "",
          ancestorTagsName: parentTag.ancestorTagsName.concat(childTag.name),
          tagName: childTag.name,
          children: [],
          parentTag: parentTag,
          depth: parentTag.depth + 1,
          treeEnd: true,
        });
      }
      parentTag.children.push(...newChildren);
      updatedData.splice(parentRowIndex + 1, 0, ...newChildren);
      setTableData(updatedData);
    }
  };

  const removeTagFromRow = (tagToDelete, rowIndex) => {
    const updatedData = [...tableData];
    let deleteCount = 1;
    // delete from table data
    for (let i = rowIndex + 1; i < updatedData.length; i++) {
      if (updatedData[i].depth > tagToDelete.depth) {
        deleteCount++;
      } else {
        break;
      }
    }
    updatedData.splice(rowIndex, deleteCount);
    // delete from parent.children
    if (tagToDelete.parentTag !== undefined) {
      for (let i = 0; i < tagToDelete.parentTag.children.length; i++) {
        if (
          tagToDelete.parentTag.children[i].conditionValue ===
          tagToDelete.conditionValue
        ) {
          tagToDelete.parentTag.children.splice(i, 1);
          break;
        }
      }
    }
    setTableData(updatedData);
  };

  const getHierarchicalRulesForSelectedTag = () => {
    const rulesForSelectedTag = [];
    tableData.forEach((tagRule, index) => {
      if (tagRule.depth === 0) {
        rulesForSelectedTag.push(tagRule);
      }
    });
    return rulesForSelectedTag;
  };

  const saveRules = async () => {
    if (selectedTag === "") {
      return;
    }

    const hierarchicalRules = getHierarchicalRulesForSelectedTag();
    await uploadTagRules(hierarchicalRules, usedCatalog)
      .then(() => {
        toast.success({
          title: "Success",
          description: "Rules successfully saved!",
        });
      })
      .catch((error) => {
        console.error("Failed to upload rules:", error);
        toast.error({
          title: "Error",
          description: "Rules saving failed. Please try again.",
        });
      });
  };

  const toggleDropdown = (rowIndex) => {
    setEditingRowIndex(rowIndex);
    setDropdownOpen((prev) => !prev);
  };

  const renderTagRows = (tags) =>
    tags.map((tag, rowIndex) => (
      <Fragment key={rowIndex}>
        <tr className="border-b">
          <td className="px-4 py-2" style={{ paddingLeft: tag.depth * 20 }}>
            {tag.depth > 0 && (
              <button
                className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => removeTagFromRow(tag, rowIndex)}
              >
                -
              </button>
            )}
            {tag["tagName"]}
          </td>
          <td className="px-4 py-2">{tag["conditionValue"]}</td>
          <td className="px-4 py-2">
            <div className="flex items-center">
              {!tag.treeEnd && (
                <button
                  className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  onClick={() => toggleDropdown(rowIndex)}
                >
                  +
                </button>
              )}
            </div>
            {!tag.treeEnd && dropdownOpen && editingRowIndex === rowIndex && (
              <div
                ref={dropdownRef}
                className="absolute mt-2 w-[30vw] bg-white shadow-lg border rounded-md z-10 overflow-auto h-[30vh]"
              >
                {Object.values({
                  ...availableTags.llm.tagger_params.tag_dict,
                })
                  .filter((childTag) => {
                    return !tag.ancestorTagsName.includes(childTag.name);
                  })
                  .map((childTag) => (
                    <div
                      key={childTag.name}
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => addTagToRow(tag, childTag, rowIndex)}
                    >
                      {childTag.name}
                    </div>
                  ))}
              </div>
            )}
          </td>
        </tr>
      </Fragment>
    ));

  return (
    <div className="flex flex-col w-full h-full relative p-5 z-40 bg-white rounded">
      <h1 className="text-lg font-semibold text-grey">
        Tagging Rules Configuration
      </h1>
      <div className="flex flex-col mt-2">
        <div className={`flex flex-col w-full justify-between`}>
          <input
            type="text"
            placeholder="Search conditions..."
            onChange={(e) => setConditionFilter(e.target.value)}
            className={`mb-2 px-3 py-2 border rounded shadow-sm ${disabledClass}`}
          />
          <div className="flex flex-row w-full justify-between mt-4">
            <div className="w-full flex flex-col">
              <Listbox value={selectedTag} onChange={setSelectedTag}>
                {({ open }) => (
                  <>
                    <Listbox.Label className="block text-sm font-medium text-gray-700">
                      <p className="text-sm text-gray-600">
                        {!selectedTag
                          ? "Select a tag to see or define its rules."
                          : "Configure rules for the selected tag."}
                      </p>
                    </Listbox.Label>
                    <div className="relative mt-1 w-[30%]">
                      <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                        <span className="block truncate">
                          {selectedTag || "Select a tag"}
                        </span>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                          <SelectorIcon
                            className="w-5 h-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>
                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 w-full py-1 mt-1 text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm overflow-auto">
                          <div className="p-2">
                            <input
                              type="text"
                              className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                              placeholder="Search tags..."
                              onChange={(e) => setFilter(e.target.value)}
                            />
                          </div>
                          {Object.values({
                            ...availableTags.llm.tagger_params.tag_dict,
                          }).map((tag) => {
                            if (tag.availableValues.length > 0) {
                              return (
                                <Listbox.Option
                                  key={tag.name}
                                  className={({ active }) =>
                                    `cursor-default select-none relative py-2 pl-10 pr-4 ${active ? "text-white bg-indigo-600" : "text-gray-900"}`
                                  }
                                  value={tag.name}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <span
                                        className={`block truncate ${selected ? "font-medium" : "font-normal"}`}
                                      >
                                        {tag.name}
                                        {allRules[tag.name] &&
                                          allRules[tag.name].length > 0 && (
                                            <CheckIcon className="inline-block w-4 h-4 ml-2 text-green-500" />
                                          )}
                                      </span>
                                      {selected && (
                                        <span
                                          className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? "text-white" : "text-indigo-600"}`}
                                        />
                                      )}
                                    </>
                                  )}
                                </Listbox.Option>
                              );
                            }
                          })}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            </div>
          </div>
        </div>
      </div>
      <PermissionGuard scope="rules" level="canEdit">
        <div className="mt-4 w-full h-full mb-20 overflow-auto">
          <table className={`w-full mt-2 text-left ${disabledClass}`}>
            <thead className="bg-gray-200">
              <tr>
                <th className="px-2 py-2">Tag</th>
                <th className="px-2 py-2">Condition</th>
                <th className="px-2 py-2">Tags triggered</th>
              </tr>
            </thead>
            <tbody>{renderTagRows(tableData)}</tbody>
          </table>
        </div>
      </PermissionGuard>
      <PermissionGuard scope="rules" level="canEdit">
        {!showConnectData && (
          <div className="flex flex-row justify-start">
            <button
              className="text-lg mt-4 bg-primary hover:bg-grey text-white font-bold py-2 px-4 rounded"
              onClick={() => saveRules(selectedTag)}
              style={{ opacity: 1 }}
              title={"Please select a tag to save rules."}
            >
              Save Rules for {selectedTag}
            </button>
          </div>
        )}
      </PermissionGuard>
      {showConnectData && (
        <div className="w-full flex items-start justify-start absolute left-5 bottom-10">
          <button
            className="cursor-pointer mt-4 bg-grey hover:bg-grey text-white font-bold py-2 px-4 rounded z-50"
            onClick={() => setView("s3List")}
          >
            <FontAwesomeIcon icon={faArrowLeft} /> Back
          </button>
        </div>
      )}
      <div className="w-full flex items-end justify-end absolute bottom-10 right-5 gap-3">
        {showConnectData && (
          <>
            <button
              className={`mt-4 bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded z-50 ${disabledClass}`}
              onClick={() => saveRules(selectedTag)}
            >
              Save Rules
            </button>
            <button
              onClick={handleSkip}
              className="px-4 py-2 bg-primary text-white hover:bg-grey rounded z-50"
            >
              {selectedTag ? "Continue" : "Skip"}
            </button>
          </>
        )}
      </div>
    </div>
  );
}
