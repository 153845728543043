import { CircularProgress, MenuItem, Select } from "@mui/material";
import { startOfMonth } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { useTeamTokenUsage } from "../../../../../../api/queryHooks";
import { formatDateInputValue } from "../../../../../../utils/datetime";
import { TokenUsageGraph } from "./TokenUsageGraph";
import { ChartEndpointEntry } from "./types";
import { tokenListToCharEndpointData } from "./utils";

import "./styles.css";

export const TeamTokenUsageComponent = () => {
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    startOfMonth(new Date()),
    new Date(),
  ]);
  const { isLoading, data } = useTeamTokenUsage(dateRange[0], dateRange[1]);
  const [selectedUser, setSelectedUser] = useState<string>("");

  const users = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.users_usage.map((user_usage) => ({
      username: user_usage.username,
      email: user_usage.email,
    }));
  }, [data]);

  const perUserChartData: { [key: string]: ChartEndpointEntry[] } = useMemo(() => {
    if (!data) {
      return {};
    }

    return data.users_usage.reduce((acc, user_usage) => {
      return {
        ...acc,
        [user_usage.username]: tokenListToCharEndpointData(
          user_usage.token_usage.token_list,
        ),
      };
    }, {});
  }, [data]);

  const perUserTokenUsageData: {
    [key: string]: { total_cost: number; total_token_cost: number };
  } = useMemo(() => {
    if (!data) {
      return {};
    }

    return data.users_usage.reduce((acc, user_usage) => {
      return {
        ...acc,
        [user_usage.username]: {
          total_cost: user_usage.token_usage.token_list,
          total_token_cost: user_usage.token_usage.token_list,
        },
      };
    }, {});
  }, [data]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!data || selectedUser) {
      return;
    }

    setSelectedUser(data.users_usage[0].username);
  });

  return (
    <div className="p-3">
      <div className="flex flex-col md:flex-row justify-center items-center gap-6 mt-4">
        <div>
          <label className="block text-gray-800 font-medium mb-1">Start:</label>
          <input
            type="date"
            disabled={isLoading}
            className="form-input mt-1 block w-full border-2 border-gprimary rounded-lg shadow-lg focus:border-primary focus:ring-2 focus:primary focus:ring-opacity-50 transition duration-200 ease-in-out"
            value={formatDateInputValue(dateRange[0])}
            onChange={(e) =>
              setDateRange(([_, oldEnd]) => [new Date(e.target.value), oldEnd])
            }
            max={formatDateInputValue(dateRange[1])}
          />
        </div>
        <div>
          <label className="block text-gray-800 font-medium mb-1">End:</label>
          <input
            type="date"
            disabled={isLoading}
            className="form-input mt-1 block w-full border-2 border-primary rounded-lg shadow-lg focus:primary focus:ring-2 focus:primary focus:ring-opacity-50 transition duration-200 ease-in-out"
            value={formatDateInputValue(dateRange[1])}
            onChange={(e) =>
              setDateRange(([oldStart, _]) => [
                oldStart,
                new Date(e.target.value),
              ])
            }
            min={formatDateInputValue(dateRange[0])}
            max={formatDateInputValue(new Date())}
          />
        </div>
        <div>
          <label className="block text-gray-800 font-medium mb-1">
            Select User
          </label>
          <Select
            disabled={isLoading}
            className="form-input mt-1 block w-full border-2 border-primary rounded-lg shadow-lg focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-50 transition duration-200 ease-in-out userUsageSelect"
            value={selectedUser}
            onChange={(e) => setSelectedUser(e.target.value as string)}
          >
            {users.map((user_info) => (
              <MenuItem key={user_info.username} value={user_info.username}>
                {user_info.email}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      {isLoading && (
        <div className="text-gray-500 text-lg text-center py-10">
          <CircularProgress />
        </div>
      )}
      {!isLoading && selectedUser && (
        <div className="space-y-6">
          <div className="shadow-lg rounded-lg overflow-hidden">
            <TokenUsageGraph data={perUserChartData[selectedUser]} />
          </div>
        </div>
      )}
    </div>
  );
};