import { useCallback, useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataContext } from "../../../context/DataContext";
import { useAtom } from "jotai";

import {
  faFolder,
  faChevronRight,
  faChevronDown,
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FileCheckbox } from "../FileCheckbox";
import { pdfAtom, pdfSearchAtom } from "../../../atoms";
import { DocumentViewer } from "../DocumentViewer/DocumentViewer";
import { toast } from "../Toast";
import { useExternalDocumentInfoLoader } from "../../../api/queryHooks";

const FolderList = ({
  checkedItems,
  currentFolder,
  filteredFolderKeys,
  folders,
  integration,
  searchText,
  setCheckedItems,
  setCurrentFolder,
  setSearchText,
  mode = "standard",
}) => {
  const {
    catalogFiles,
    quarantinedFiles,
    catalogGetsRenamed,
    usedCatalog,
    setShowFileOnPage,
  } = useContext(DataContext);

  const [pdf, setPdf] = useAtom(pdfAtom);
  const [pdfSearch, setPdfSearch] = useAtom(pdfSearchAtom);

  const handleCheckboxChange = (file, folderKey) => {
    setCheckedItems((prevState) => {
      const isChecked = !(prevState[file]?.isChecked ?? false);
      if (!isChecked && prevState[file]) {
        const { [file]: removedFile, ...rest } = prevState;
        return rest;
      }
      return {
        ...prevState,
        [file]: {
          isChecked,
          folder: folderKey,
          source: integration,
        },
      };
    });
  };

  const handleFolderCheckboxChange = (folderKey) => {
    setCheckedItems((prevCheckedItems) => {
      const newCheckedItems = { ...prevCheckedItems };
      const filesInFolder = folders[folderKey] || [];
      const allChecked = filesInFolder.every(
        (file) => newCheckedItems[file]?.isChecked,
      );

      if (allChecked) {
        filesInFolder.forEach((file) => {
          if (newCheckedItems[file]) {
            delete newCheckedItems[file];
          }
        });
      } else {
        filesInFolder.forEach((file) => {
          newCheckedItems[file] = {
            isChecked: true,
            folder: folderKey,
            source: integration,
          };
        });
      }

      return newCheckedItems;
    });
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleFolderClick = (folderKey) => {
    setCurrentFolder((prevFolder) =>
      prevFolder === folderKey ? null : folderKey,
    );
  };
  const loadDocumentInfo = useExternalDocumentInfoLoader();

  const openFile = useCallback(
    async (docKey, folderKey) => {
      toast.info({
        title: "Opening file...",
        description: "This may take a few seconds.",
      });

      const documentInfo = await loadDocumentInfo(
        docKey,
        folderKey,
        integration,
      );
      if (
        documentInfo.file_name.endsWith(".pdf") ||
        documentInfo.file_name.endsWith(".docx")
      ) {
        setShowFileOnPage(mode);
        setPdf(documentInfo.file_url.toString());
        setPdfSearch("");
      } else {
        window.open(documentInfo.file_url, "_blank");
      }
    },
    [
      integration,
      loadDocumentInfo,
      setPdf,
      setShowFileOnPage,
      mode,
      setPdfSearch,
    ],
  );

  const renderItems = () => {
    return (
      <>
        <div className="w-full">
          <input
            type="text"
            placeholder="Search..."
            className="w-full px-4 py-2 rounded-t-md outline-none border"
            value={searchText}
            onChange={handleSearchChange}
          />
        </div>
        {pdf && (
          <DocumentViewer
            url={pdf}
            onClose={() => {
              setPdf("");
              setShowFileOnPage(null);
              setPdfSearch("");
            }}
            search={pdfSearch}
          />
        )}
        <div className="flex flex-col overflow-hidden pb-[7vh]">
          <ul className="w-full text-sm h-full overflow-auto">
            {filteredFolderKeys &&
              filteredFolderKeys.map((folderKey) => {
                const filesInFolder = folders[folderKey] || [];

                const filteredFiles =
                  mode === "autoCreation"
                    ? filesInFolder.filter((file) =>
                        catalogFiles.hasOwnProperty(file),
                      )
                    : filesInFolder;

                if (filteredFiles.length === 0) return null;

                const totalFiles = filteredFiles.length;

                const containedFiles = filteredFiles.filter(
                  (file) => catalogFiles[file] || quarantinedFiles[file],
                ).length;

                const containedFilesPercentage = Math.round(
                  (containedFiles / totalFiles) * 100,
                );

                const filesWithKeyQuestions = filteredFiles.filter(
                  (file) => catalogFiles[file]?.Key_Questions,
                ).length;

                const keyQuestionsPercentage = Math.round(
                  (filesWithKeyQuestions / filteredFiles.length) * 100,
                );

                const isFolderSelected = filteredFiles.every(
                  (file) => checkedItems[file]?.isChecked,
                );
                return (
                  <li
                    key={folderKey}
                    className={`border-b px-2 ${
                      isFolderSelected ? "selected" : ""
                    } ${
                      containedFilesPercentage === 100 && mode === "standard"
                        ? "!bg-light !bg-opacity-40"
                        : "bg-slate-100"
                    }`}
                  >
                    <div
                      className={`flex gap-2 items-center cursor-pointer py-2`}
                      onClick={() => handleFolderClick(folderKey)}
                    >
                      <FontAwesomeIcon
                        icon={
                          currentFolder === folderKey
                            ? faChevronDown
                            : faChevronRight
                        }
                      />

                      <input
                        type="checkbox"
                        id={`checkbox-folder-${folderKey}`}
                        onChange={() => handleFolderCheckboxChange(folderKey)}
                        checked={filteredFiles.every(
                          (file) => checkedItems[file]?.isChecked,
                        )}
                      />
                      <div htmlFor={`checkbox-folder-${folderKey}`}>
                        <FontAwesomeIcon
                          icon={faFolder}
                          className="pr-1 text-yellow-400"
                        />
                        {folderKey}
                      </div>
                      <span className="file-count">
                        {totalFiles} files
                        {mode !== "autoCreation" && (
                          <>
                            {" "}
                            ({containedFilesPercentage.toFixed(0)}% contained)
                          </>
                        )}
                        {mode === "autoCreation" && (
                          <> ({keyQuestionsPercentage.toFixed(0)}% prepared)</>
                        )}
                      </span>
                    </div>
                    {currentFolder === folderKey && (
                      <ul className="file-tree">
                        {filesInFolder
                          .filter((file) =>
                            file
                              .toLowerCase()
                              .includes(searchText.toLowerCase()),
                          )
                          .map((file, index) => {
                            const isContained =
                              catalogFiles[file] || quarantinedFiles[file];
                            const hasKeyQuestions =
                              catalogFiles[file]?.Key_Questions ?? false;

                            return (
                              <li
                                key={file}
                                className={`p-2 flex gap-2 items-center border-b border-x justify-between md:flex-nowrap flex-wrap break-all ${
                                  isContained &&
                                  (mode === "standard" || mode === "tagStudio")
                                    ? "bg-light bg-opacity-30"
                                    : index % 2
                                      ? "bg-slate-50"
                                      : "bg-slate-100"
                                }`}
                              >
                                <FileCheckbox
                                  fileName={file}
                                  isSelected={
                                    checkedItems[file]?.isChecked ?? false
                                  }
                                  onClick={() =>
                                    handleCheckboxChange(file, currentFolder)
                                  }
                                />
                                <div className="flex items-center md:w-auto w-full justify-end">
                                  {mode === "autoCreation" && (
                                    <FontAwesomeIcon
                                      icon={
                                        hasKeyQuestions
                                          ? faCheckCircle
                                          : faTimesCircle
                                      }
                                      className={
                                        hasKeyQuestions
                                          ? "text-green-500"
                                          : "text-red-500"
                                      }
                                      title={
                                        hasKeyQuestions
                                          ? "Data is prepared"
                                          : "Data is not prepared"
                                      }
                                    />
                                  )}
                                  <button
                                    onClick={() => openFile(file, folderKey)}
                                    className="bg-primary px-2 py-1 text-white rounded-md whitespace-nowrap ml-2"
                                  >
                                    Open
                                  </button>
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                    )}
                  </li>
                );
              })}
          </ul>
        </div>
      </>
    );
  };

  useEffect(() => {
    setCheckedItems([]);
  }, []);

  return (
    <div className="w-full h-full overflow-hidden flex flex-col">
      {!catalogGetsRenamed ? (
        renderItems()
      ) : (
        <div className="h-[700vh] p-4 flex flex-col rounded-md overflow-hidden justify-center items-center">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-primary"></div>
          <p className="mt-4 text-lg font-semibold">Loading {usedCatalog}...</p>
        </div>
      )}
    </div>
  );
};

export default FolderList;
