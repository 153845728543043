import { useEffect, useState } from "react";
import "./Export.css";
import { sendRequest } from "../../../functions/api";
import { ENDPOINTS } from "../../../../../api/endpoints";
import { API_USERNAME_KEYWORD } from "../../../../../constants/fixedValues";
import Auth from "../../../../../auth/AuthProvider";
import { useCatalogNames } from "../../../../../api/queryHooks";
import { usePersistedState } from "../../../../../pages/hooks";

export default function Export() {
  const { data: catalogNames = [] } = useCatalogNames();
  const [outputFormat, setOutputFormat] = usePersistedState("outputFormat", "");
  const [outputCatalog, setOutputCatalog] = usePersistedState("outputCatalog", "");

  const handleOptionChange = (option) => {
    setOutputCatalog(option);
  };

  const handleFormatChange = (event) => {
    setOutputFormat(event.target.value);
  };

  const handleDownload = async () => {
    const creds = (await Auth.currentAuthenticatedUser()).username;
    const sendDetails = {
      output_format: outputFormat,
      catalog_name: outputCatalog,
      [API_USERNAME_KEYWORD]: creds,
    };

    const response = await sendRequest(sendDetails, ENDPOINTS["export"]);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${outputCatalog}.${outputFormat}`;
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
  };

  const handleConfirm = async () => {
    if (!outputFormat || !catalogNames.includes(outputCatalog)) {
      window.confirm(
        "Please select an output format and a valid export catalog."
      );
    } else {
      await handleDownload();
    }
  };

  return (
    <div className="export-options-container">
      <h3>Select Export Options</h3>
      <div className="export-options-select-container">
        <select
          value={outputCatalog}
          onChange={(event) => handleOptionChange(event.target.value)}
        >
          <option value="">Select Catalog</option>
          {catalogNames &&
            catalogNames.map((catalog, index) => (
              <option key={index} value={catalog}>
                {catalog}
              </option>
            ))}
        </select>
      </div>

      <h3>Select Output Format</h3>
      <select value={outputFormat} onChange={handleFormatChange}>
        <option value="">Select Format</option>
        <option value="csv">CSV</option>
        <option value="json">JSON</option>
      </select>

      <button onClick={handleConfirm} className="bg-primary">
        Export
      </button>
    </div>
  );
}
