import { useState, useContext, useEffect, useMemo } from "react";
import { Select, MenuItem } from "@mui/material";
import Auth from "../../../../../auth/AuthProvider";
import { DataContext } from "../../../../../context/DataContext";
import { sendRequest } from "../../../../utilities/functions/api";
import { ENDPOINTS } from "../../../../../api/endpoints";
import FolderList from "../../../../utilities/NavigationBar/FolderList";
import InfoIcon from "../../../../utilities/InfoIcon/InfoIcon";
import { useFolderEntriesAvailable } from "../../../../../api/queryHooks";
import { TagContext } from "../../../../../context/TagContext";
import ScoreCard from "./ScoreCard";
import { NonTaskProgressBar } from "../../../../utilities/NavigationBar/Components/ProgressBar/ProgressBar";
import { toast } from "../../../../utilities/Toast";
import { uploadTags } from "../../../../utilities/functions/apiCalls";
import { CircularProgress } from "@mui/material";

const FILTERMAPPING = {
  "Defined Values": "custom",
  "Yes No": "yesNo",
  "Open ended tags": "aiGenerated",
};

const OUTPUTTYPES = ["word", "number", "date"];

export default function AutoCreateTag() {
  const {
    preferences,
    handleCatalogChange,
    usedCatalog,
    setAvailableTags,
    catalogFiles,
  } = useContext(DataContext);

  const {
    autoCreatedTags,
    setAutoCreatedTags,
    evaluatedScores,
    setEvaluatedScores,
  } = useContext(TagContext);

  const { data: folderEntryByStorageKind, isLoadingFolderEntires } =
    useFolderEntriesAvailable();
  const [currentFolder, setCurrentFolder] = useState(null);
  const [checkedItems, setCheckedItems] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filePreviewContent, setFilePreviewContent] = useState("");
  const [showFilePreview, setShowFilePreview] = useState(false);
  const [progress, setProgress] = useState(0);
  const [filterOption, setFilterOption] = useState("");
  const [sortOption, setSortOption] = useState("");
  const [evaluatingTag, setEvaluatingTag] = useState(null);
  const [outputTypeFilter, setOutputTypeFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTags, setSelectedTags] = useState(new Set());
  const [selectAllTags, setSelectAllTags] = useState(false);

  const [isFolderFetched, setIsFolderFetched] = useState(false);
  const [autoCreateTagContext, setAutoCreateTagContext] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (!autoCreateTagContext && autoCreateTagContext !== "proceed_without_context") return;
    handleGenerateTags();
    setIsModalOpen(false);
  }, [autoCreateTagContext]);

  const handleModalSubmit = (context) => {
    setAutoCreateTagContext(context || "proceed_without_context");
  };
  
  const getUniqueTagId = (tag) => `${tag.name}-${tag.option}`;

  const availableStorageKinds = useMemo(
    () =>
      Object.keys(folderEntryByStorageKind ?? {}).filter(
        (storageKind) =>
          Object.keys(folderEntryByStorageKind[storageKind]).length > 0,
      ),
    [folderEntryByStorageKind],
  );
  const [selectedStorageKind, setSelectedStorageKind] = useState(
    availableStorageKinds[0],
  );
  const selectedFolderEntry = useMemo(
    () => folderEntryByStorageKind?.[selectedStorageKind] ?? {},
    [folderEntryByStorageKind, selectedStorageKind],
  );

  const filteredFolderKeys = useMemo(() => {
    return Object.keys(selectedFolderEntry).filter(
      (foldePath) =>
        foldePath.toLowerCase().includes(searchText.toLowerCase()) ||
        selectedFolderEntry[foldePath]?.some((file) =>
          file.toLowerCase().includes(searchText.toLowerCase()),
        ),
    );
  }, [selectedFolderEntry, searchText]);

  const filteredTags = useMemo(() => {
    return autoCreatedTags.filter(
      (tag) =>
        (filterOption === "" || tag.option === FILTERMAPPING[filterOption]) &&
        (outputTypeFilter === "" || tag.output_type === outputTypeFilter),
    );
  }, [autoCreatedTags, filterOption, outputTypeFilter]);

  const selectAll = () => {
    setSelectAllTags((prevState) => !prevState);
    const currentState = !selectAllTags;
    if (currentState) {
      const allTags = new Set(autoCreatedTags.map((tag) => tag.name));
      setSelectedTags(allTags);
    } else {
      setSelectedTags(new Set());
    }
  };

  const toggleTag = (tag) => {
    const tagId = `${tag.name}-${tag.option}`;
    setSelectedTags((prevSelectedTags) => {
      const newSelectedTags = new Set(prevSelectedTags);
      if (newSelectedTags.has(tagId)) {
        newSelectedTags.delete(tagId);
      } else {
        newSelectedTags.add(tagId);
      }
      return newSelectedTags;
    });
  };

  useEffect(() => {
    if (!isLoading) return;

    const checkProgress = () => {
      const selectedFiles = Object.keys(checkedItems);
      const totalItems = selectedFiles.length;
      let preparedItems = 0;

      selectedFiles.forEach((file) => {
        if (catalogFiles[file] && catalogFiles[file]?.Key_Questions) {
          preparedItems++;
        }
      });

      setProgress(Math.round((preparedItems / totalItems) * 90));
    };

    const interval = setInterval(checkProgress, 1000);

    return () => clearInterval(interval);
  }, [isLoading, checkedItems, catalogFiles]);

  const PreviewModal = ({ isOpen, onClose, content }) => {
    if (!isOpen) return null;

    return (
      <div className="modal-backdrop">
        <div className="modal-content">
          <button
            onClick={onClose}
            className="modal-close-button hover:bg-primary m-3"
          >
            &times;
          </button>
          <div className="modal-body">{content}</div>
        </div>
      </div>
    );
  };

  const ContextModal = ({ isOpen, onClose, onSubmit }) => {
    const [input, setInput] = useState('');

    if (!isOpen) return null;

    const handleSubmit = (event) => {
      event.preventDefault();
      onSubmit(input.trim());
      setInput('');
    };

    const handleGenerateWithoutContext = () => {
      onSubmit("proceed_without_context");
      setInput('');
      onClose();
    };
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <div className="bg-white p-6 rounded-lg">
          <h2 className="text-xl mb-2"><strong>Narrow auto-suggestion domain</strong> (optional)</h2>
          <p className="text-base text-gray-600 mb-4">
            Provide a few words of context to focus tag suggestion on a certain domain
          </p>
          <form onSubmit={handleSubmit}>
            <textarea
              value={input}
              onChange={(e) => setInput(e.target.value)}
              className="border p-2 w-full mb-4"
              placeholder="Enter context (optional)"
              rows="3"
            />
            <div className="flex justify-end">
              <button type="button" onClick={onClose} className="mr-2 px-4 py-2 bg-gray-200 rounded">Cancel</button>
              <button type="submit" className="mr-2 px-4 py-2 bg-primary text-white rounded">Submit</button>
              <button type="button" onClick={handleGenerateWithoutContext} className="mr-2 px-4 py-2 bg-white text-primary border border-primary rounded">
              Generate without context
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  const handleEvaluateTags = async (tags) => {
    try {
      const username = (await Auth.currentAuthenticatedUser()).username;

      for (let tag of tags) {
        setEvaluatingTag(tag.name);

        const tagToRun = {
          ...autoCreatedTags.find((autoTag) => autoTag.name === tag.name),
        };

        const requestParams = {
          auto_created_tags: [tagToRun],
          [preferences.system.API_USERNAME_KEYWORD]: username,
          key_questions: Object.values(catalogFiles)
            .map((file) => file.Key_Questions || [])
            .flat(),
        };

        const evaluateTagsResponse = await sendRequest(
          requestParams,
          ENDPOINTS["evaluate_tags"],
        );

        if (!evaluateTagsResponse) {
          console.error("No response received for tag:", tag.name);
          continue;
        }

        const rawEvaluatedTagData = await evaluateTagsResponse.json();
        const evaluatedTagData = rawEvaluatedTagData.evaluated_tags[0];

        setEvaluatedScores((prevScores) => ({
          ...prevScores,
          [evaluatedTagData.name]: evaluatedTagData.score,
        }));

        await new Promise((resolve) => setTimeout(resolve, 500));
      }

      setEvaluatingTag(null);
    } catch (error) {
      console.log("Error during the request", error);
    }
  };

  const handleGenerateTags = async () => {
    setAutoCreatedTags([]);
    setEvaluatedScores({});
    setIsLoading(true);
    setProgress(0);

    try {
      const selectedFiles = Object.keys(checkedItems);
      const totalItems = selectedFiles.length;

      let preparedItems = 0;
      const updateProgress = (increment) => {
        setProgress((prevProgress) => prevProgress + increment);
      };

      for (const file of selectedFiles) {
        if (catalogFiles[file] && catalogFiles[file]?.Key_Questions) {
          preparedItems++;
        }

        await new Promise((resolve) => setTimeout(resolve, 100));
        updateProgress(Math.round(preparedItems / totalItems));
      }

      const isNoContext = autoCreateTagContext === "proceed_without_context";

      const requestParams = {
        file_names: selectedFiles,
        tag_context: isNoContext ? '' : autoCreateTagContext,
        [preferences.system.API_USERNAME_KEYWORD]: (
          await Auth.currentAuthenticatedUser()
        ).username,
        catalog_name: usedCatalog,
      };
      
      const autoCreateTagResponse = await sendRequest(
        requestParams,
        ENDPOINTS["auto_create_tags"],
      );

      const createdTags = await autoCreateTagResponse.json();
      const autoTags = createdTags.auto_created_tags;

      setAutoCreatedTags(autoTags);
      setProgress(100);
      setIsLoading(false);
    } catch (error) {
      console.log("Error during the request", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const evaluateTags = async () => {
      if (isLoading && autoCreatedTags.length > 0) {
        await handleEvaluateTags(autoCreatedTags);
      }
    };

    if (isLoading && autoCreatedTags.length > 0) {
      evaluateTags();
    }
  }, [autoCreatedTags, isLoading, handleEvaluateTags]);

  useEffect(() => {
    if (
      selectedStorageKind ||
      isLoadingFolderEntires ||
      !availableStorageKinds
    ) {
      return;
    }

    setSelectedStorageKind(availableStorageKinds[0]);
  }, [availableStorageKinds, isLoadingFolderEntires, selectedStorageKind]);

  const sortTags = (tags) => {
    if (sortOption === "score") {
      return tags.sort((a, b) => {
        const aScore = evaluatedScores[a.name] || 0;
        const bScore = evaluatedScores[b.name] || 0;
        return bScore - aScore;
      });
    } else if (sortOption === "name") {
      return tags.sort((a, b) => a.name.localeCompare(b.name));
    }
    return tags;
  };

  const closePreviewModal = () => {
    setShowFilePreview(false);
  };

  const handleConfirmSelection = async () => {
    let countNewTags = 0;
    let updatedTags = {};

    const confirmSelection = window.confirm(
      `Are you sure you want to add ${selectedTags.size} tags?`,
    );
    if (!confirmSelection) {
      return;
    }

    setAvailableTags((prevAvailableTags) => {
      const updatedTagDict = {
        ...prevAvailableTags.llm.tagger_params.tag_dict,
      };
      const startingNumberAvaibleTags = Object.keys(updatedTagDict).length;
      selectedTags.forEach((tagId) => {
        const tagDetails = autoCreatedTags.find(
          (tag) => getUniqueTagId(tag) === tagId,
        );
        if (tagDetails.name.includes(",")) {
          toast.warning({
            title: "Tag name contains invalid character",
            description: `Tag name '${tagDetails.name}' contains invalid character ',' and will not be added to the tag library`,
          });
        } else if (tagDetails) {
          updatedTagDict[tagDetails.name] = { ...tagDetails };
        }
      });
      countNewTags =
        Object.keys(updatedTagDict).length - startingNumberAvaibleTags;

      updatedTags = {
        ...prevAvailableTags,
        llm: {
          ...prevAvailableTags.llm,
          tagger_params: {
            ...prevAvailableTags.llm.tagger_params,
            tag_dict: updatedTagDict,
          },
        },
      };

      return updatedTags;
    });

    if (countNewTags > 0) {
      try {
        await uploadTags(updatedTags, usedCatalog);
        toast.success({
          title: "Success",
          description: `Added ${countNewTags} tag${countNewTags > 1 ? `s` : ""}`,
        });
      } catch (error) {
        console.error(`Error uploading tags: ${error.message}`);
        toast.error({
          title: "Error",
          description: `Error uploading tags: ${error.message}`,
        });
      }
    } else {
      alert(`Tag(s) already in library`);
    }
  };

  if (folderEntryByStorageKind) {
    console.debug({ folderEntryByStorageKind });
  }

  return (
    <div className="flex bg-white h-full w-full justify-between flex-row p-5 rounded-md gap-5">
      <div className="flex flex-col w-2/5 gap-2 relative">
        <header className="p-2 text-gray-700 font-bold text-lg">
          Select data to create tags from{" "}
          <InfoIcon
            infoText="Deasie is distilling your datasets to create meaningful tags. Select one or more datasets to auto-create Deasie tags.
          If data is prepared, you can generate Deasie tags at a lower latency."
          />
        </header>
        <div className="flex justify-between w-full">
          <Select
            className="w-40"
            value={selectedStorageKind}
            onChange={(e) => setSelectedStorageKind(e.target.value)}
          >
            {availableStorageKinds.map((storageKind) => (
              <MenuItem value={storageKind} key={storageKind}>
                {storageKind}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="w-full flex justify-center items-center overflow-y-auto h-full">
          {isLoadingFolderEntires ? (
            <CircularProgress />
          ) : (
            <FolderList
              checkedItems={checkedItems}
              integration={selectedStorageKind}
              currentFolder={currentFolder}
              filteredFolderKeys={filteredFolderKeys}
              folders={selectedFolderEntry}
              searchText={searchText}
              setCheckedItems={setCheckedItems}
              setCurrentFolder={setCurrentFolder}
              setSearchText={setSearchText}
            />
          )}
        </div>
        <PreviewModal
          isOpen={showFilePreview}
          onClose={closePreviewModal}
          content={
            <div
              dangerouslySetInnerHTML={{
                __html: filePreviewContent,
              }}
            />
          }
        />
        <div className="absolute bottom-5 flex p-2 w-full justify-start items-start z-100">
          <button
            onClick={() => setIsModalOpen(true)}
            className={`${
              autoCreatedTags && Object.keys(autoCreatedTags).length !== 0
                ? "text-primary border-2 border-primary bg-white hover:font-bold"
                : "text-white"
            } p-3 w-[50%] flex flex-col rounded-md items-center justify-start  text-xl  transition-all duration-300 ease-in-out ${
              Object.keys(checkedItems).length !== 0
                ? "bg-primary hover:font-bold"
                : "bg-slate-300"
            }`}
            disabled={Object.keys(checkedItems).length === 0}
          >
            {Object.keys(checkedItems).length === 0 ? (
              "Select one or more datasets"
            ) : isLoading ? (
              <p>...Loading</p>
            ) : (
              "Generate Tags"
            )}
          </button>
        </div>
      </div>
      <ContextModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleModalSubmit}
      />
      <div className="flex flex-col w-3/5 overflow-y-hidden">
        <div className="flex flex-row w-full justify-between items-center">
          <header className="p-2 text-gray-700 font-bold text-lg">
            Auto-generated tags{" "}
            <InfoIcon
              infoText={
                autoCreatedTags && Object.keys(autoCreatedTags).length > 0
                  ? "Choose tags to be added to your tag library"
                  : "Please choose datasets to generate your custom tags"
              }
            />
          </header>

          {autoCreatedTags && Object.keys(autoCreatedTags).length > 0 && (
            <button
              onClick={selectAll}
              className="font-bold text-md text-primary border-2 border-primary text-gray-800 px-4 py-2 rounded focus:outline-none focus:ring-2 focus:ring-gray-500 transition duration-150 ease-in-out"
            >
              {selectAllTags ? "Deselect All" : "Select All"}
            </button>
          )}
        </div>

        {autoCreatedTags && Object.keys(autoCreatedTags).length > 0 && (
          <div className="flex flex-row gap-4 items-center mb-4">
            <div className="flex gap-2 items-center">
              <label className="text-md font-bold">Filter by Option:</label>
              <select
                className="border-2 border-deasieBlack rounded-md px-2 py-1"
                onChange={(e) => setFilterOption(e.target.value)}
                value={filterOption}
              >
                <option value="">All</option>
                {Object.keys(FILTERMAPPING).map((key) => (
                  <option key={key} value={key}>
                    {key}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex gap-2 items-center">
              <label className="text-md font-bold">
                Filter by Output Type:
              </label>
              <select
                className="border-2 border-deasieBlack rounded-md px-2 py-1"
                onChange={(e) => setOutputTypeFilter(e.target.value)}
                value={outputTypeFilter}
              >
                <option value="">All</option>
                {OUTPUTTYPES.map((type) => (
                  <option key={type} value={type}>
                    {type.charAt(0).toUpperCase() + type.slice(1)}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex gap-2 items-center">
              <label className="text-md font-bold">Sort by:</label>
              <select
                className="border-2 border-deasieBlack rounded-md px-2 py-1"
                onChange={(e) => setSortOption(e.target.value)}
                value={sortOption}
              >
                <option value="">None</option>
                <option value="score">Weighted Score</option>
                <option value="name">Tag Name</option>
              </select>
            </div>
            {autoCreatedTags && Object.keys(autoCreatedTags).length > 0 && (
              <button
                onClick={() => handleEvaluateTags(filteredTags)}
                className="font-bold text-md text-white bg-primary border-2 border-primary px-4 py-2 rounded focus:outline-none focus:ring-2 focus:ring-gray-500 transition duration-150 ease-in-out"
              >
                Evaluate Tags
              </button>
            )}
          </div>
        )}

        <div className="flex flex-col overflow-y-auto w-full no-scrollbar">
          {!isLoading &&
            sortTags(filteredTags).map((tag, index) => (
              <div
                key={`${tag.name}-${tag.option}`}
                className={`transition hide-scrollbar duration-500 ease-in-out p-10 rounded-lg shadow-md hover:shadow-lg mb-4 cursor-pointer ${
                  selectedTags.has(`${tag.name}-${tag.option}`)
                    ? "border-primary border-4"
                    : "bg-gray-50 text-gray-800 hover:bg-gray-100"
                }`}
                onClick={() => toggleTag(tag)}
              >
                <div className="flex justify-between items-center mb-4 w-full">
                  <div>
                    <h3 className="text-lg font-semibold text-gray-800">
                      {tag.name}
                    </h3>
                    <p className="text-gray-600">{tag.description}</p>
                  </div>
                  <span
                    title="This is the output type of the tag"
                    className={`text-sm font-medium px-2 py-1 rounded ${
                      tag.output_type === "word"
                        ? "bg-deasieTurquoise text-white"
                        : tag.output_type === "number"
                          ? "bg-deasieBlack text-white"
                          : "bg-yellow-200 text-yellow-800"
                    }`}
                  >
                    {tag.output_type.charAt(0).toUpperCase() +
                      tag.output_type.slice(1)}
                  </span>
                </div>
                <div className="flex flex-row w-full justify-between whitespace-nowrap">
                  {tag.option !== "aiGenerated" && (
                    <div className="mt-2">
                      <h4 className="text-sm font-medium text-gray-700">
                        Available Values:
                      </h4>
                      <ul className="list-disc list-inside">
                        {tag.availableValues.map((value, valueIndex) => (
                          <li key={valueIndex} className="text-gray-600">
                            {value}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  <div className="text-center mt-4 flex flex-col justify-start w-full items-end space-y-2">
                    <ScoreCard
                      tag={tag}
                      evaluatingTag={evaluatingTag}
                      evaluatedScores={evaluatedScores}
                    />
                  </div>
                </div>
              </div>
            ))}
        </div>

        {filteredTags && filteredTags.length > 0 && !isLoading ? (
          <div className="flex flex-row w-full justify-end pb-8 pt-2">
            <button
              onClick={handleConfirmSelection}
              className={`flex flex-col text-white border-2 border-primary bg-primary px-4 py-2 rounded-md text-xl hover:bg-primary-dark transition-all duration-300 ease-in-out ${
                selectedTags.size === 0 && "opacity-50 cursor-not-allowed"
              }`}
              disabled={selectedTags.size === 0}
            >
              Add Selected Tags ({selectedTags.size})
            </button>
          </div>
        ) : (
          <div className="w-full h-full flex justify-center items-center">
            {isLoading && <NonTaskProgressBar progress={progress} />}
          </div>
        )}
      </div>
    </div>
  );
}
