import { useState, useEffect, useContext } from "react";
import Modal from "../../../../../../../../utilities/Modal/Modal";
import "./EvidenceTable.css";
import { useAtom } from "jotai";
import { pdfAtom, pdfSearchAtom } from "../../../../../../../../../atoms";
import {
  DataContext,
  useDataContext,
} from "../../../../../../../../../context/DataContext";
import { sendRequest } from "../../../../../../../../../components/utilities/functions/api";
import Auth from "../../../../../../../../../auth/AuthProvider";
import { toast } from "../../../../../../../../utilities/Toast";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import { ENDPOINTS } from "../../../../../../../../../api/endpoints";
import { API_USERNAME_KEYWORD } from "../../../../../../../../../constants/fixedValues";
import { useCatalogDocumentInfoLoader } from "../../../../../../../../../api/queryHooks";

export const DownloadIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 9H15V3H9V9H5L12 16L19 9ZM5 18V21H19V18H5Z"
      fill="currentColor"
    />
  </svg>
);

const cleanSearch = (text = "") => {
  return text.replace(/ /g, "").replace(/_/g, "").toLowerCase();
};

export default function EvidenceTable(props) {
  const { preferences } = useContext(DataContext);

  const [tagFilter, setTagFilter] = useState("");
  const [chunkFilter, setChunkFilter] = useState("");
  const [evidenceFilter, setEvidenceFilter] = useState("");
  const [reasonFilter, setReasonFilter] = useState("");
  const [valuesFilter, setValuesFilter] = useState("");
  const [fileNameFilter, setFileNameFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [pendingChanges, setPendingChanges] = useState({});
  const [previousValues, setPreviousValues] = useState({});
  const [pdf, setPdf] = useAtom(pdfAtom);
  const [, setPdfSearch] = useAtom(pdfSearchAtom);
  const { usedCatalog, setShowFileOnPage } = useDataContext();
  const [collapsedCollumns, setCollapsedCollumns] = useState(
    new Set(["Reason"]),
  );
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const loadDocumentInfo = useCatalogDocumentInfoLoader();

  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentChange, setCurrentChange] = useState({
    tag: "",
    chunk: "",
    newValue: "",
    oldValue: "",
    field: "values",
  });

  const showModalConfirmation = (tag, chunk, newValue, oldValue) => {
    setCurrentChange({ tag, chunk, newValue, oldValue, field: "values" });
    setIsModalOpen(true);
  };

  const updateTagValueInCatalog = async ({
    catalog,
    tag,
    oldValue,
    newValue,
  }) => {
    const creds = (await Auth.currentAuthenticatedUser()).username;
    const sendDetails = {
      [API_USERNAME_KEYWORD]: creds,
      catalog_name: catalog,
      tag_name: tag,
      prev_value: oldValue,
      new_value: newValue,
    };

    await sendRequest(
      sendDetails,
      ENDPOINTS["apply_tag_value_change_to_catalog"],
    );
  };

  const applyValueChangeToAll = async () => {
    const { tag, newValue, oldValue } = currentChange;
    const newData = props.evidenceData.map((data) => {
      if (data.tag === tag) {
        return {
          ...data,
          evidences: data.evidences.map((evidence) => {
            const updatedValues = evidence.values.map((value) => {
              if (value === oldValue) {
                return newValue;
              }
              return value;
            });
            return {
              ...evidence,
              values: updatedValues,
            };
          }),
        };
      }
      return data;
    });

    props.setEvidenceData(newData);
    setIsModalOpen(false);

    // update database
    try {
      updateTagValueInCatalog({
        catalog: usedCatalog,
        tag: tag,
        oldValue: oldValue,
        newValue: newValue,
      });
      toast.success({ title: "Changes saved successfully!" });
    } catch (error) {
      toast.error({ title: "Changes saved failed, please try again" });
    } finally {
      setIsLoading(false);
    }
  };

  const updateTagValueInChunk = async ({
    catalog,
    tag,
    oldValue,
    newValue,
    chunk,
  }) => {
    const creds = (await Auth.currentAuthenticatedUser()).username;
    const sendDetails = {
      [API_USERNAME_KEYWORD]: creds,
      catalog_name: catalog,
      tag_name: tag,
      prev_value: oldValue,
      new_value: newValue,
      chunk_id: chunk,
    };

    await sendRequest(sendDetails, ENDPOINTS["apply_single_tag_value_change"]);
  };

  const applySingleValueChange = async () => {
    const { tag, chunk, newValue, oldValue } = currentChange;
    console.log("chunk id", chunk);
    const newData = props.evidenceData.map((data) => {
      if (data.tag === tag) {
        const updatedEvidences = data.evidences.map((evidence) => {
          if (evidence.chunk === chunk) {
            const updatedValues = evidence.values.map((value) => {
              if (value === oldValue) {
                return newValue;
              }
              return value;
            });
            return {
              ...evidence,
              values: updatedValues,
            };
          }
          return evidence;
        });
        return {
          ...data,
          evidences: updatedEvidences,
        };
      }
      return data;
    });
    props.setEvidenceData(newData);
    setIsModalOpen(false);

    try {
      updateTagValueInChunk({
        catalog: usedCatalog,
        tag: tag,
        oldValue: oldValue,
        newValue: newValue,
        chunk: chunk,
      });
      toast.success({ title: "Changes saved successfully!" });
    } catch (error) {
      toast.error({ title: "Changes saved failed, please try again" });
    }
  };

  const [currentValue, setCurrentValue] = useState("");
  const [isTyping, setIsTyping] = useState(false);

  async function fetchUpdatedEvidence() {
    setIsLoading(true);

    try {
      const creds = (await Auth.currentAuthenticatedUser()).username;
      const sendDetails = {
        [API_USERNAME_KEYWORD]: creds,
        catalog_name: usedCatalog,
      };

      const response = await sendRequest(
        sendDetails,
        ENDPOINTS["get_evidence"],
      );
      const { updated_evidence } = await response.json();

      const updatedTagsData = props.evidenceData.map((tagData) => ({
        ...tagData,
        evidences: tagData.evidences.map((evidence) => {
          const evidenceKey = `${evidence.fileName}_${evidence.chunk}_${tagData.tag}`;
          const evidenceUpdates = updated_evidence[evidenceKey] || {};
          return {
            ...evidence,
            isSubmitted: evidenceUpdates.is_submitted ?? false,
            isValid: evidenceUpdates.is_valid ?? true,
          };
        }),
      }));

      props.setEvidenceData(updatedTagsData);
    } catch (error) {
      console.error("Failed to fetch updated evidence:", error);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    if (props.isEvidenceModalOpen) {
      fetchUpdatedEvidence();
    }
  }, [props.isEvidenceModalOpen]);

  useEffect(() => {
    const isSaveDisabled = !filteredData.some(
      ({ evidence }) => !evidence.isValid || evidence.isSubmitted,
    );
    setIsSaveDisabled(isSaveDisabled);
  }, [filteredData]);

  useEffect(() => {
    setValuesFilter(
      (prev) => prev || preferences?.profile?.DEFAULT_VALUE_FILTER,
    );
  }, [preferences?.profile?.DEFAULT_VALUE_FILTER]);

  useEffect(() => {
    const newFilteredData = props.evidenceData?.flatMap(
      ({ tag, evidences }) => {
        const sortedEvidences = evidences.sort((a, b) => {
          const indexA = Object.keys(props.sortedChunkMappings).indexOf(
            a.chunk,
          );
          const indexB = Object.keys(props.sortedChunkMappings).indexOf(
            b.chunk,
          );
          return indexA - indexB;
        });

        return sortedEvidences
          .filter((evidence) => {
            const fileMatch =
              typeof evidence.fileName === "string"
                ? cleanSearch(evidence.fileName).includes(
                    cleanSearch(fileNameFilter),
                  )
                : true;
            const tagMatch =
              typeof tag === "string"
                ? cleanSearch(tag).includes(cleanSearch(tagFilter))
                : true;
            const chunkMatch =
              typeof (
                props.sortedChunkMappings[evidence.chunk] || evidence.chunk
              ) === "string"
                ? cleanSearch(
                    props.sortedChunkMappings[evidence.chunk] || evidence.chunk,
                  ).includes(cleanSearch(chunkFilter))
                : true;
            const evidenceMatch =
              typeof evidence.evidence === "string"
                ? cleanSearch(evidence.evidence).includes(
                    cleanSearch(evidenceFilter),
                  )
                : true;
            const reasonMatch =
              typeof evidence.reason === "string"
                ? cleanSearch(evidence.reason).includes(
                    cleanSearch(reasonFilter),
                  )
                : true;
            const valuesMatch = Array.isArray(evidence.values)
              ? evidence.values.some(
                  (value) =>
                    typeof value === "string" &&
                    cleanSearch(value).includes(cleanSearch(valuesFilter)),
                )
              : true;

            return (
              fileMatch &&
              tagMatch &&
              chunkMatch &&
              evidenceMatch &&
              reasonMatch &&
              valuesMatch
            );
          })
          .map((evidence) => ({
            tag,
            evidence: {
              ...evidence,
              originalChunk: evidence.chunk,
              chunk:
                props.sortedChunkMappings[evidence.chunk] || evidence.chunk,
            },
          }));
      },
    );

    setFilteredData(newFilteredData);
  }, [
    chunkFilter,
    evidenceFilter,
    props.evidenceData,
    props.sortedChunkMappings,
    reasonFilter,
    tagFilter,
    valuesFilter,
    fileNameFilter,
  ]);

  const handleDownload = () => {
    const csvRows = [
      [
        "FileName",
        "Tag",
        "Chunk",
        "Evidence",
        "Reason",
        "Values",
        "IsCorrect",
        "IsSubmitted",
      ].join(","),
      ...props.evidenceData.flatMap(({ tag, evidences }) =>
        evidences.map((evidence) =>
          [
            `"${evidence.fileName}"`,
            tag,
            evidence.chunk,
            `"${evidence.evidence}"`,
            `"${evidence.reason}"`,
            evidence.values.join("; "),
            evidence.isValid ? "Yes" : "No",
            evidence.isSubmitted ? "Yes" : "No",
          ].join(","),
        ),
      ),
    ];
    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "evidence-data.csv";
    document.body.appendChild(a);
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
  };

  const handleSave = async () => {
    const payloads = Object.values(pendingChanges).map((el) => ({
      filename: el.updatedTag.fileName,
      chunk: el.chunk,
      tagname: el.tag,
      tag_value: el.updatedTag.values,
      evidence: el.updatedTag.evidence,
      reason: el.updatedTag.reason,
      catalogname: usedCatalog,
      is_valid: el.updatedTag.isValid,
      is_submitted: el.updatedTag.isSubmitted,
    }));

    const creds = (await Auth.currentAuthenticatedUser()).username;

    try {
      const updatePromises = payloads.map((payload) => {
        const sendDetails = {
          evidence: JSON.stringify(payload),
          [API_USERNAME_KEYWORD]: creds,
          catalog: usedCatalog,
        };
        return sendRequest(sendDetails, ENDPOINTS["update_evidence"]);
      });

      await Promise.all(updatePromises);

      toast.success({ title: "Changes saved successfully!" });
      setPendingChanges({});
    } catch (error) {
      console.error("Error updating evidence:", error);
      toast.error({ title: "Failed to save changes. Please try again." });
    }
  };

  const handleInputChange = (tag, evidence, field, value) => {
    const updatedTag = {
      ...evidence,
      [field]: value,
    };

    const changeKey = `${tag}_${evidence.chunk}`;
    const existingChange = pendingChanges[changeKey];

    if (existingChange) {
      const updatedChange = {
        ...existingChange,
        updatedTag: {
          ...existingChange.updatedTag,
          [field]: value,
        },
      };
      setPendingChanges({
        ...pendingChanges,
        [changeKey]: updatedChange,
      });
    } else {
      setPendingChanges({
        ...pendingChanges,
        [changeKey]: {
          tag,
          chunk: evidence.chunk,
          updatedTag,
          old_value: previousValues[changeKey],
        },
      });
    }

    const result = props.evidenceData.find(
      (data) =>
        data.tag === tag &&
        data.evidences.some((e) => e.chunk === evidence.chunk),
    );

    if (result) {
      const evidenceIndex = result.evidences.findIndex(
        (e) => e.chunk === evidence.chunk,
      );
      if (evidenceIndex !== -1) {
        result.evidences[evidenceIndex] = updatedTag;
        props.setEvidenceData([...props.evidenceData]);
      }
    }
  };

  const handleCheckboxChange = (tag, evidence, field, value) => {
    setPreviousValues((prev) => ({
      ...prev,
      [`${tag}_${evidence.chunk}_${field}`]: evidence[field],
    }));

    handleInputChange(tag, evidence, field, value);
  };

  const handleCollapse = (column) => {
    if (collapsedCollumns.has(column)) {
      collapsedCollumns.delete(column);
    } else {
      collapsedCollumns.add(column);
    }
    setCollapsedCollumns(new Set(collapsedCollumns));
  };

  return (
    <Modal
      isOpen={props.isEvidenceModalOpen}
      onClose={() => {
        if (pdf) return;
        props.setIsEvidenceModalOpen(false);
        props.setShowAllEvidence(false);
        props.setIsEvidenceModalOpen(false);
      }}
      title="Evidence List"
    >
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-4 rounded-lg shadow-lg max-w-lg w-full mx-4">
            <div className="text-lg text-black p-4 border border-gray-300 shadow-lg rounded">
              <p>
                Do you want to apply this change to all same tag values '
                {currentChange.oldValue}' in this catalog?
              </p>
            </div>
            <div className="mt-4 flex justify-around">
              <button
                className="bg-primary w-4/12 text-white m-2 p-2 rounded-md"
                onClick={() => {
                  applyValueChangeToAll();
                }}
              >
                Apply to all
              </button>
              <button
                className="bg-red-500 w-4/12 text-white m-2 p-2 rounded-md"
                onClick={() => {
                  applySingleValueChange();
                }}
              >
                Just change this one
              </button>
            </div>
          </div>
        </div>
      )}
      {isLoading ? null : (
        <div className="flex h-full relative overflow-hidden py-10">
          <div className="flex absolute bg-white top-2 right-1 justify-end w-full items-center gap-4">
            <div className="text-base">{props.currentItemKey}</div>
            <button onClick={handleDownload} className="download-button">
              <DownloadIcon />
            </button>
            <button
              className={`bg-primary text-white py-2 px-4 text-center text-sm rounded ${isSaveDisabled ? "bg-gray-300 cursor-not-allowed disabled opacity-30" : "hover:bg-deasieTurquoise"} `}
              disabled={isSaveDisabled}
              onClick={handleSave}
            >
              Save
            </button>
          </div>
          <table className="evidence-table relative">
            <thead>
              <tr>
                <th>
                  <div className="flex flex-col">
                    <div className="flex justify-between gap-2 items-center py-2">
                      <div className="text-nowrap">File Name</div>
                      <div
                        className="cursor-pointer"
                        onClick={() => handleCollapse("File Name")}
                      >
                        {collapsedCollumns.has("File Name") ? (
                          <FaArrowCircleRight />
                        ) : (
                          <FaArrowCircleLeft />
                        )}
                      </div>
                    </div>
                    <input
                      type="text"
                      style={{
                        width: collapsedCollumns.has("File Name") ? 60 : "auto",
                      }}
                      onChange={(e) => setFileNameFilter(e.target.value)}
                      placeholder="Filter by File Name"
                    />
                  </div>
                </th>
                <th>
                  <div className="flex flex-col">
                    <div className="flex justify-between gap-2 items-center py-2">
                      <div className="text-nowrap">Tag</div>
                      <div
                        className="cursor-pointer"
                        onClick={() => handleCollapse("Tag")}
                      >
                        {collapsedCollumns.has("Tag") ? (
                          <FaArrowCircleRight />
                        ) : (
                          <FaArrowCircleLeft />
                        )}
                      </div>
                    </div>
                    <input
                      type="text"
                      style={{
                        width: collapsedCollumns.has("Tag") ? 60 : "auto",
                      }}
                      onChange={(e) => setTagFilter(e.target.value)}
                      placeholder="Filter by Tag"
                    />
                  </div>
                </th>
                <th>
                  <div className="flex flex-col">
                    <div className="flex justify-between gap-2 items-center py-2">
                      <div className="text-nowrap">Chunk</div>
                      <div
                        className="cursor-pointer"
                        onClick={() => handleCollapse("Chunk")}
                      >
                        {collapsedCollumns.has("Chunk") ? (
                          <FaArrowCircleRight />
                        ) : (
                          <FaArrowCircleLeft />
                        )}
                      </div>
                    </div>
                    <input
                      type="text"
                      style={{
                        width: collapsedCollumns.has("Chunk") ? 60 : "auto",
                      }}
                      onChange={(e) => setChunkFilter(e.target.value)}
                      placeholder="Filter by Chunk"
                    />
                  </div>
                </th>
                <th>
                  <div className="flex flex-col">
                    <div className="flex justify-between gap-2 items-center py-2">
                      <div className="text-nowrap">Values</div>
                      <div
                        className="cursor-pointer"
                        onClick={() => handleCollapse("Values")}
                      >
                        {collapsedCollumns.has("Values") ? (
                          <FaArrowCircleRight />
                        ) : (
                          <FaArrowCircleLeft />
                        )}
                      </div>
                    </div>
                    <input
                      type="text"
                      className={
                        valuesFilter
                          ? preferences?.profile?.DEFAULT_VALUE_FILTER ===
                              valuesFilter && "bg-yellow-200"
                          : ""
                      }
                      style={{
                        width: collapsedCollumns.has("Values") ? 60 : "auto",
                      }}
                      onChange={(e) => setValuesFilter(e.target.value)}
                      value={valuesFilter}
                      placeholder="Filter by Value"
                    />
                  </div>
                </th>
                <th>
                  <div className="flex flex-col">
                    <div className="flex justify-between gap-2 items-center py-2">
                      <div className="text-nowrap">Evidence</div>
                      <div
                        className="cursor-pointer"
                        onClick={() => handleCollapse("Evidence")}
                      >
                        {collapsedCollumns.has("Evidence") ? (
                          <FaArrowCircleRight />
                        ) : (
                          <FaArrowCircleLeft />
                        )}
                      </div>
                    </div>
                    <input
                      type="text"
                      style={{
                        width: collapsedCollumns.has("Evidence") ? 60 : "auto",
                      }}
                      onChange={(e) => setEvidenceFilter(e.target.value)}
                      placeholder="Filter by Evidence"
                    />
                  </div>
                </th>
                <th>
                  <div className="flex flex-col">
                    <div className="flex justify-between gap-2 items-center py-2">
                      <div className="text-nowrap">Reason</div>
                      <div
                        className="cursor-pointer"
                        onClick={() => handleCollapse("Reason")}
                      >
                        {collapsedCollumns.has("Reason") ? (
                          <FaArrowCircleRight />
                        ) : (
                          <FaArrowCircleLeft />
                        )}
                      </div>
                    </div>
                    <input
                      type="text"
                      style={{
                        width: collapsedCollumns.has("Reason") ? 60 : "auto",
                      }}
                      onChange={(e) => setReasonFilter(e.target.value)}
                      placeholder="Filter by Reason"
                    />
                  </div>
                </th>
                <th className="whitespace-nowrap">Is Correct</th>
                <th className="whitespace-nowrap">Is Submitted</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map(({ tag, evidence }, index) => (
                <tr key={index}>
                  <td>
                    <div className="max-w-xs break-all">
                      {collapsedCollumns.has("File Name") ? (
                        <>...</>
                      ) : (
                        <>{evidence.fileName}</>
                      )}
                    </div>
                  </td>
                  <td>
                    {collapsedCollumns.has("Tag") ? <>...</> : <>{tag}</>}
                  </td>
                  <td>
                    {collapsedCollumns.has("Chunk") ? (
                      <>...</>
                    ) : (
                      <>{evidence.chunk}</>
                    )}
                  </td>
                  <td>
                    {collapsedCollumns.has("Values") ? (
                      <>...</>
                    ) : (
                      <>
                        {evidence.isValid ? (
                          evidence.values.map((value, valueIndex) => (
                            <div key={valueIndex}>{value}</div>
                          ))
                        ) : (
                          <div style={{ position: "relative" }}>
                            <textarea
                              defaultValue={evidence.values?.[0]}
                              onChange={(e) => {
                                setCurrentValue(e.target.value);
                                setIsTyping(true);
                              }}
                              onBlur={() => setIsTyping(false)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.preventDefault();
                                  setIsTyping(false);
                                  showModalConfirmation(
                                    tag,
                                    evidence.chunk,
                                    currentValue,
                                    evidence.values[0],
                                  );
                                }
                              }}
                            />
                            {isTyping && (
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: "100%",
                                  left: "50%",
                                  transform: "translateX(-50%)",
                                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                                  color: "white",
                                  padding: "5px 10px",
                                  borderRadius: "5px",
                                  fontSize: "12px",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Click Enter to save
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </td>
                  <td>
                    {collapsedCollumns.has("Evidence") ? (
                      <>...</>
                    ) : (
                      <>
                        {evidence.fileName.endsWith(".pdf") ||
                        evidence.fileName.endsWith(".docx") ? (
                          <div
                            className="bg-zinc-100 rounded-md p-2 border cursor-pointer w-full max-w-xs"
                            onClick={async () => {
                              toast.info({ title: "Loading document..." });
                              const documentInfo = await loadDocumentInfo(
                                evidence.fileName,
                              );
                              setShowFileOnPage("standard");
                              setPdf(documentInfo.file_url.toString());
                              setPdfSearch(JSON.stringify([evidence.evidence]));
                            }}
                          >
                            {evidence.evidence}
                          </div>
                        ) : (
                          <div className="w-full max-w-xs rounded-md p-2 border overflow-auto">
                            {evidence.evidence}
                          </div>
                        )}
                      </>
                    )}
                  </td>
                  <td>
                    {collapsedCollumns.has("Reason") ? (
                      <>...</>
                    ) : (
                      <div className="w-full max-w-xs">{evidence.reason}</div>
                    )}
                  </td>
                  <td>
                    <div className="flex justify-center h-full">
                      <input
                        type="checkbox"
                        checked={evidence.isValid}
                        onChange={(e) =>
                          handleCheckboxChange(
                            tag,
                            evidence,
                            "isValid",
                            e.target.checked,
                          )
                        }
                      />
                    </div>
                  </td>
                  <td>
                    <div className="flex justify-center h-full">
                      <input
                        type="checkbox"
                        checked={evidence.isSubmitted}
                        onChange={(e) =>
                          handleCheckboxChange(
                            tag,
                            evidence,
                            "isSubmitted",
                            e.target.checked,
                          )
                        }
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </Modal>
  );
}
