import { ChartDataEntry, ChartEndpointEntry, TokenEntry } from "./types";

export const tokenListToChartData = (
  tokenList: TokenEntry[],
): ChartDataEntry[] => {
  const result = tokenList.reduce(
    (groupedByDate, entry) => {
      if (!groupedByDate[entry.date]) {
        groupedByDate[entry.date] = {
          date: entry.date,
          models: [],
          promptTokens: 0,
          completionTokens: 0,
          totalTokens: 0,
        };
      }

      entry.model_costs.forEach((model) => {
        groupedByDate[entry.date].models.push(model);
        groupedByDate[entry.date].promptTokens += Number(model.prompt_tokens);
        groupedByDate[entry.date].completionTokens += Number(
          model.completion_tokens,
        );
        groupedByDate[entry.date].totalTokens += Number(model.total_tokens);
      });

      return groupedByDate;
    },
    {} as { [key: string]: ChartDataEntry },
  );
  console.log(Object.values(result));
  return Object.values(result);
};

export const tokenListToCharEndpointData = (
  tokenList: TokenEntry[],
): ChartEndpointEntry[] => {
  const result = tokenList.reduce(
    (groupedByEndpoint, entry: TokenEntry) => {
      if (!groupedByEndpoint[entry.endpoint]) {
        groupedByEndpoint[entry.endpoint] = {
          endpoint: entry.endpoint,
          promptTokens: 0,
          completionTokens: 0,
          totalTokens: 0,
          tokensLeft: 0, // not used, add it after we set the max token per endpoint
          models: {},
        };
      }
      entry.model_costs.forEach((model) => {
        groupedByEndpoint[entry.endpoint].promptTokens += Number(model.prompt_tokens);
        groupedByEndpoint[entry.endpoint].completionTokens += Number(model.completion_tokens);
        groupedByEndpoint[entry.endpoint].totalTokens += Number(model.total_tokens);
        if (!groupedByEndpoint[entry.endpoint].models[model.name]) {
          groupedByEndpoint[entry.endpoint].models[model.name] = {
            name: model.name,
            prompt_tokens: 0,
            completion_tokens: 0,
            total_tokens: 0,
          };
        }
        groupedByEndpoint[entry.endpoint].models[model.name].completion_tokens += Number(model.completion_tokens);
        groupedByEndpoint[entry.endpoint].models[model.name].prompt_tokens += Number(model.prompt_tokens);
        groupedByEndpoint[entry.endpoint].models[model.name].total_tokens += Number(model.total_tokens);
      })

      return groupedByEndpoint;
    },
    {} as { [key: string]: ChartEndpointEntry },
  )
  return Object.values(result);
}