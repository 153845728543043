import { useState, useEffect, useContext, useMemo } from "react";
import { Tab, Tabs, Select, MenuItem } from "@mui/material";
import { DataContext } from "../../../../../context/DataContext";
import FolderList from "../../../../utilities/NavigationBar/FolderList";
import { CgSpinner } from "react-icons/cg";
import { TagContext } from "../../../../../context/TagContext";
import {
  useCatalogDocumentInfoLoader,
  useCatalogNames,
  useFolderEntriesAvailable,
} from "../../../../../api/queryHooks";
import { useAtom } from "jotai";
import { pdfAtom, pdfSearchAtom } from "../../../../../atoms";
import { DocumentViewer } from "../../../../utilities/DocumentViewer/DocumentViewer";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

// Set the workerSrc globally
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function TaggingStudio({ setPreviousExamples }) {
  const {
    currentTag,
    setCurrentTag,
    handleCatalogChange,
    usedCatalog,
    showFileOnPage,
    setShowFileOnPage,
  } = useContext(DataContext);

  const {
    tagStudioLoadingStatus,
    setTagStudioLoadingStatus,
    tagStudioCheckedItems,
    tagStudioResponses,
    setTagStudioResponses,
    setTagStudioCheckedItems,
    handleTagTest,
  } = useContext(TagContext);

  const { data: folderEntryByStorageKind, isLoadingFolderEntires } =
    useFolderEntriesAvailable();
  const [currentFolder, setCurrentFolder] = useState(null);
  const [searchText, setSearchText] = useState("");
  const availableStorageKinds = useMemo(
    () =>
      Object.keys(folderEntryByStorageKind ?? {}).filter(
        (storageKind) =>
          Object.keys(folderEntryByStorageKind?.[storageKind] ?? {}).length > 0,
      ),
    [folderEntryByStorageKind],
  );
  const [selectedStorageKind, setSelectedStorageKind] = useState(
    availableStorageKinds[0],
  );
  const selectedFolderEntry = useMemo(
    () => folderEntryByStorageKind?.[selectedStorageKind] ?? {},
    [folderEntryByStorageKind, selectedStorageKind],
  );

  const filteredFolderKeys = useMemo(() => {
    return Object.keys(selectedFolderEntry).filter(
      (foldePath) =>
        foldePath.toLowerCase().includes(searchText.toLowerCase()) ||
        selectedFolderEntry[foldePath]?.some((file) =>
          file.toLowerCase().includes(searchText.toLowerCase()),
        ),
    );
  }, [selectedFolderEntry, searchText]);

  const [pdf, setPdf] = useAtom(pdfAtom);
  const [pdfSearch, setPdfSearch] = useAtom(pdfSearchAtom);

  const [userResponses, setUserResponses] = useState({});
  const { data: catalogNames = [] } = useCatalogNames();
  const loadDocumentInfo = useCatalogDocumentInfoLoader();

  const handleValidationResponse = (evidence, value, isYes) => {
    setUserResponses((prevResponses) => ({
      ...prevResponses,
      [evidence]: {
        response: isYes ? "Yes" : "No",
        value: value,
      },
    }));
  };

  const viewEvidence = async (file, folderKey, evidence) => {
    if (!folderKey || !file || !evidence) {
      console.error("Missing required parameters for viewing evidence");
      return;
    }

    try {
      const documentInfo = await loadDocumentInfo(file);

      if (
        documentInfo.file_name.endsWith(".pdf") ||
        documentInfo.file_name.endsWith(".docx")
      ) {
        setPdf(documentInfo.file_url.toString());
        setPdfSearch(JSON.stringify([evidence]));
      } else {
        window.open(documentInfo.file_url, "_blank");
      }
    } catch (error) {
      console.error("Error fetching the document", error);
    }
  };

  useEffect(() => {
    if (
      selectedStorageKind ||
      isLoadingFolderEntires ||
      !availableStorageKinds
    ) {
      return;
    }

    setSelectedStorageKind(availableStorageKinds[0]);
  }, [availableStorageKinds, isLoadingFolderEntires, selectedStorageKind]);

  useEffect(() => {
    setTagStudioResponses([]);
    setTagStudioLoadingStatus({});
  }, [setTagStudioLoadingStatus, setTagStudioResponses]);

  const handleConfirmButtonClick = () => {
    const yesResponses = Object.entries(userResponses).filter(
      ([_, { response, evidence }]) => response === "Yes" && evidence !== "",
    );

    const noResponses = Object.entries(userResponses).filter(
      ([_, { response, evidence }]) => response === "No" && evidence !== "",
    );

    const newExamples = yesResponses.map(([evidence, { value }]) => ({
      evidence: evidence,
      value: `${value.value}`,
    }));

    setPreviousExamples(newExamples);

    const newNegExamples = noResponses.map(([evidence, { value }]) => ({
      evidence: evidence,
      value: `${value.value}`,
    }));

    const existingExamples = Array.isArray(currentTag.examples)
      ? currentTag.examples
      : [];

    const existingNegExamples = Array.isArray(currentTag.neg_examples)
      ? currentTag.neg_examples
      : [];

    setCurrentTag({
      ...currentTag,
      examples: [...existingExamples, ...newExamples],
      neg_examples: [...existingNegExamples, ...newNegExamples],
    });

    setUserResponses({});
    setTagStudioCheckedItems([]);
  };

  const selectedCatalog = useMemo(() => {
    return catalogNames.includes(usedCatalog) ? usedCatalog : "";
  }, [usedCatalog, catalogNames]);

  return (
    <div className="flex flex-col w-full bg-white rounded-md overflow-hidden h-full">
      <div className="flex h-full gap-4">
        <div className="w-full flex flex-col justify-between h-full">
          <div className="overflow-hidden">
            <div className="flex flex-col gap-1 p-4 bg-slate-200 mt-4">
              <header className="text-grey font-bold text-lg">
                1. Select testing data{" "}
              </header>
              <p className="">
                Data selected for testing will not be added to the data catalog
              </p>
            </div>
            <div className="flex flex-col gap-4 p-4">
              <div className="flex gap-4">
                <div className="flex justify-between w-full">
                  <Select
                    className="w-40"
                    value={selectedStorageKind}
                    onChange={(e) => setSelectedStorageKind(e.target.value)}
                  >
                    {availableStorageKinds.map((storageKind) => (
                      <MenuItem value={storageKind} key={storageKind}>
                        {storageKind}
                      </MenuItem>
                    ))}
                  </Select>
                  <Select
                    className="w-40"
                    onChange={handleCatalogChange}
                    value={selectedCatalog}
                  >
                    <MenuItem value="" disabled>
                      Select a catalog
                    </MenuItem>
                    {catalogNames.map((catalog, index) => (
                      <MenuItem key={index} value={catalog}>
                        {catalog}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="flex gap-2 items-center"></div>
              </div>
              <div className="flex gap-2">
                <div className="flex flex-row gap-2">
                  <div className="bg-light opacity-80 w-[5px] h-[5px] p-2 border-2 rounded-md">
                    <div className="bg-light"></div>
                  </div>
                </div>
                <p className="text-bold text-grey text-md opacity-80">
                  Data already in the catalog
                </p>
              </div>
            </div>

            <div className="flex flex-col justify-between border-r overflow-hidden h-[75%]">
              {isLoadingFolderEntires ? (
                <div className="w-full h-full flex justify-center items-center">
                  <CgSpinner className="text-4xl animate-spin" />
                </div>
              ) : (
                <>
                  <div className="w-full flex justify-center items-center overflow-hidden">
                    <FolderList
                      checkedItems={tagStudioCheckedItems}
                      currentFolder={currentFolder}
                      filteredFolderKeys={filteredFolderKeys}
                      folders={selectedFolderEntry}
                      integration={selectedStorageKind}
                      mode={"tagStudio"}
                      searchText={searchText}
                      setCheckedItems={setTagStudioCheckedItems}
                      setCurrentFolder={setCurrentFolder}
                      setSearchText={setSearchText}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="pl-4">
            <button
              className={`text-lg py-2 rounded-md text-primary border-2 border-primary font-bold w-[160px] ${
                Object.keys(tagStudioCheckedItems).length === 0 &&
                "cursor-not-allowed opacity-50"
              }`}
              onClick={handleTagTest}
            >
              Run Test
            </button>
          </div>
        </div>
        <div className="flex overflow-hidden h-full flex-col w-full">
          {tagStudioResponses && (
            <div className="flex flex-col gap-1 p-4 bg-slate-200 mt-4">
              <header className="text-grey font-bold text-lg">
                2. Validate outputs
              </header>
              <p>
                View outputs and select "Correct" to fine-tune the tag with this
                example
              </p>
            </div>
          )}
          <div className="overflow-auto break-all flex flex-col gap-2 h-full w-full">
            {tagStudioLoadingStatus &&
              Object.entries(tagStudioLoadingStatus).map(
                ([documentName, isLoading]) =>
                  isLoading && (
                    <div
                      key={documentName}
                      className="animate-pulse font-small text-gray-700 p-2"
                    >
                      Loading {documentName}...
                    </div>
                  ),
              )}

            {tagStudioResponses &&
              tagStudioResponses.map((catalogItem, catalogIndex) => {
                const documentName = Object.keys(catalogItem.catalog)[0];
                const documentDetails = catalogItem.catalog[documentName];

                return (
                  <div
                    key={`catalog-item-${catalogIndex}`}
                    className="p-4 border m-2 rounded-md"
                  >
                    <h3 className="font-semibold">
                      <span className="font-bold text-grey font-md">
                        Data:{" "}
                      </span>
                      <span className="text-grey font-md">{documentName}</span>
                    </h3>
                    {documentDetails.chunks &&
                      Object.entries(documentDetails.chunks).map(
                        ([chunkKey, chunkDetails]) => {
                          return (
                            <div key={chunkKey} className="mt-2">
                              {Object.entries(chunkDetails).map(
                                ([key, value], detailIndex) => {
                                  if (
                                    key === "status" ||
                                    (!Array.isArray(value) &&
                                      typeof value !== "object")
                                  ) {
                                    return null;
                                  }

                                  const displayValue = Array.isArray(value)
                                    ? value[0]
                                    : value.value;
                                  const evidence = Array.isArray(value)
                                    ? value[2]
                                    : value.evidence;

                                  if (
                                    displayValue === "No" ||
                                    displayValue === "Not found"
                                  ) {
                                    return null;
                                  }

                                  const uniqueKey = `${catalogIndex}-${chunkKey}-${detailIndex}`;

                                  return (
                                    <div
                                      className="border rounded-md p-4"
                                      key={uniqueKey}
                                    >
                                      <div className="text-white bg-slate-500 p-2 rounded-md w-fit">
                                        <strong>{currentTag.name}</strong>:{" "}
                                        {displayValue}
                                      </div>
                                      <div
                                        className="mb-4 p-2 border rounded mt-2 cursor-pointer"
                                        onClick={() =>
                                          viewEvidence(
                                            documentName,
                                            currentFolder,
                                            evidence,
                                          )
                                        }
                                      >
                                        <strong>Evidence </strong> <br />
                                        {evidence}
                                      </div>
                                      <div className="flex items-center space-x-4">
                                        <label className="flex items-center space-x-2">
                                          <input
                                            type="radio"
                                            name={`response-${uniqueKey}`}
                                            value="Yes"
                                            onChange={() =>
                                              handleValidationResponse(
                                                evidence,
                                                value,
                                                true,
                                              )
                                            }
                                          />
                                          <span>Correct</span>
                                        </label>
                                        <label className="flex items-center space-x-2">
                                          <input
                                            type="radio"
                                            name={`response-${uniqueKey}`}
                                            value="No"
                                            onChange={() =>
                                              handleValidationResponse(
                                                evidence,
                                                value,
                                                false,
                                              )
                                            }
                                          />
                                          <span>Incorrect</span>
                                        </label>
                                      </div>
                                    </div>
                                  );
                                },
                              )}
                            </div>
                          );
                        },
                      )}
                  </div>
                );
              })}
          </div>
          {tagStudioResponses && Object.keys(tagStudioResponses).length > 0 && (
            <button
              onClick={handleConfirmButtonClick}
              className="text-lg py-2 rounded-md text-primary border-2 border-primary font-bold w-[160px]"
            >
              Fine-tune
            </button>
          )}
        </div>
      </div>
      {pdf && showFileOnPage === "tagStudio" && (
        <DocumentViewer
          url={pdf}
          onClose={() => {
            setPdf("");
            setShowFileOnPage(null);
            setPdfSearch("");
          }}
          search={pdfSearch}
        />
      )}
    </div>
  );
}

export default TaggingStudio;
