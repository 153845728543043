import { faCloudUpload, faFax } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Modal,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import { FC, useState } from "react";
import { useFileUplaodMutation } from "../../../api/queryHooks";
import { toast } from "../Toast";

import "./styles.css";

type Props = {
  onClose: () => void;
  onSuccess: () => void;
  dataStore: {
    type: "s3" | "azureblob";
    name: string;
    base_path: string;
    credentials:
      | {
          access_key_id: string;
          secret_access_key: string;
        }
      | {
          client_id: string;
          client_secret: string;
          tenant_id: string;
        };
  };
};

export const FileUploadModal: FC<Props> = ({
  onClose,
  dataStore,
  onSuccess,
}) => {
  const resetAndClose = () => {
    setFiles([]);
    onClose();
  };

  const filesUplaodMutation = useFileUplaodMutation(
    { storage: dataStore },
    {
      onSuccess: () => {
        resetAndClose();
        toast.success({
          title: "Files uploaded successfully",
          description: "",
        });
        onSuccess();
      },
      onError: () => {
        toast.error({
          title: "Failed to upload files",
          description: "Try again",
        });
      },
    },
  );
  const [files, setFiles] = useState<File[]>([]);

  const readDirectory = async (dir: FileSystemDirectoryEntry) => {
    const readers = dir.createReader();

    return await new Promise<File[]>((resolve) => {
      const entries: File[] = [];
      readers.readEntries(async (results) => {
        if (!results.length) {
          return entries;
        }

        for (const entry of results) {
          if (entry instanceof FileSystemFileEntry) {
            const fileEntry = await new Promise<File>((resolve, reject) => {
              entry.file(resolve, reject);
            });
            entries.push(fileEntry);
          } else if (entry instanceof FileSystemDirectoryEntry) {
            const children = await readDirectory(entry);
            entries.push(...children);
          }
        }

        resolve(entries);
      });
    });
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const entries: File[] = [];

    for (let i = 0; i < e.dataTransfer.items.length; i++) {
      const item = e.dataTransfer.items[i];
      const entry = item.webkitGetAsEntry() as FileSystemEntry;

      if (!entry) {
        continue;
      }

      if (entry instanceof FileSystemFileEntry) {
        entry.file((fileObj) => {
          entries.push(fileObj);
        });
      } else if (entry instanceof FileSystemDirectoryEntry) {
        const files = await readDirectory(entry);

        entries.push(...files);
      }
    }

    setFiles(entries);
  };

  const submit = () => {
    filesUplaodMutation.mutate({ files });
  };

  return (
    <Modal open onClose={resetAndClose}>
      <>
        {filesUplaodMutation.isPending && (
          <Box className="FileUploadLoadingIndicator">
            <CircularProgress />
          </Box>
        )}
        {!filesUplaodMutation.isPending && (
          <Box
            className="FileUploadContainer"
            onDragOver={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onDragEnter={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onDrop={handleDrop}
          >
            <div>
              <Typography variant="h4">Upload a files</Typography>
              <Typography variant="caption">
                Drag and drop files here
              </Typography>
            </div>
            <ul className="overflow-auto">
              {files.map((file) => (
                <li key={file.name}>{file.name}</li>
              ))}
            </ul>
            <Button
              component="label"
              startIcon={<FontAwesomeIcon icon={faCloudUpload} />}
              role={undefined}
              variant="contained"
            >
              Select Files
              <input
                type="file"
                accept="image/jpeg, image/gif, image/png, .pdf, .doc, .docx, .txt, .application/msword, .xlsx, .xls"
                onChange={(e) => setFiles(Array.from(e.target.files || []))}
                multiple
              />
            </Button>
            <Box className="FileUploadControls mt-2">
              <Button type="reset" variant="outlined" onClick={resetAndClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                onClick={submit}
                disabled={!files.length || filesUplaodMutation.isPending}
                variant="contained"
              >
                Upload
              </Button>
            </Box>
          </Box>
        )}
      </>
    </Modal>
  );
};
