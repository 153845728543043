import { Document, Page } from "react-pdf";
import { toast } from "../Toast";
import { backendBaseUrl } from "../../../utils/config";
import React from "react";

export const DocumentViewer = ({ url, onClose, search }) => {
  const [numPages, setNumPages] = React.useState();
  const [pageNumber, setPageNumber] = React.useState();
  const [zoom, setZoom] = React.useState(1.3);

  const onDocumentLoadSuccess = React.useCallback(({ numPages }) => {
    setNumPages(numPages);
  }, []);

  React.useEffect(() => {
    const handler = async () => {
      const pdfUrl = new URL(
        `${backendBaseUrl}/api/catalog_data/fetch_catalog_content`,
      );
      pdfUrl.searchParams.append("pdf_url", url);
      pdfUrl.searchParams.append("search", search);
      pdfUrl.searchParams.append("just_page", "true");

      const response = await fetch(pdfUrl.href);
      const num = (await response.json()).data.page;
      if (num === -1 || num === undefined) {
        setPageNumber(1);
        if (search) {
          toast.info({
            title: "Info",
            description: "No evidence highlighted in the document.",
          });
        }
      }
      setPageNumber(num === -1 || !num ? 1 : 1 + num);
    };
    handler().catch(() => {
      setPageNumber(1);
    });
  }, [search, url]);

  const pdfUrl = new URL(
    `${backendBaseUrl}/api/catalog_data/fetch_catalog_content`,
  );
  pdfUrl.searchParams.append("pdf_url", url);
  pdfUrl.searchParams.append("just_page", "false");
  pdfUrl.searchParams.append("search", search);

  return (
    <div
      className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-10 backdrop-blur-md overflow-hidden z-[999999]"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="w-full h-full p-4 inset-0 flex flex-col gap-4 justify-center items-center overflow-hidden">
        <div
          className={`flex h-full ${pageNumber === undefined && "bg-white"} overflow-auto`}
        >
          <Document file={pdfUrl.href} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} scale={zoom} />
          </Document>
        </div>
        <div className="h-10 shrink-0 flex gap-2">
          <button
            className="bg-secondary px-4 py-2 text-white rounded-md"
            onClick={(e) => {
              if (pageNumber === undefined) return;

              e.stopPropagation();
              setPageNumber(
                (pageNumber || 1) - 1 > 0 ? pageNumber - 1 : pageNumber,
              );
            }}
          >
            prev
          </button>
          <button
            className="bg-primary px-4 py-2 text-white rounded-md"
            onClick={(e) => {
              if (!numPages) return;
              if (pageNumber === undefined) return;

              e.stopPropagation();
              setPageNumber(
                (pageNumber || 1) + 1 < numPages ? pageNumber + 1 : numPages,
              );
            }}
          >
            next
          </button>
          <button
            onClick={onClose}
            className="bg-rose-400 px-4 py-2 text-white rounded-md"
          >
            Close
          </button>
          <button
            onClick={() => setZoom(zoom + 0.1)}
            className="bg-primary ml-20 px-4 py-2 text-white rounded-md"
          >
            +
          </button>

          <button
            onClick={() => setZoom(zoom - 0.1)}
            className="bg-primary px-4 py-2 text-white rounded-md"
          >
            -
          </button>
          <div className="bg-white text-sm rounded-md flex items-center p-2">
            page {pageNumber} of {numPages}
          </div>
        </div>
      </div>
    </div>
  );
};
