import { useContext, useEffect, useMemo } from "react";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/solid";
import "./TagFilter.css";
import { DataContext } from "../../../context/DataContext";
import { TagContext } from "../../../context/TagContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";
import ProgressPie from "./PieChart";
import { CgSpinnerTwo } from "react-icons/cg";
import { selectedCatalogItemsAtom } from "../../../atoms";
import { useAtom } from "jotai";
import { PermissionGuard } from "../PermissionGuard";

export default function TagFilter({
  label,
  categoryKey,
  showScreen,
  isBeingDeleted,
  selectedTagKeys,
  setSelectedTagKeys,
}) {
  const {
    deleteAllLabels,
    availableTags,
    currentDataGroup,
    hiddenCategories,
    toggleCategoryVisibility,
    ruleDict,
  } = useContext(DataContext);
  const {
    relatedInfo,
    setRelatedInfo,
    processingTags,
    runSingleRule,
    editTag,
  } = useContext(TagContext);

  const [selectedCatalogItems] = useAtom(selectedCatalogItemsAtom);

  const tagDict = useMemo(() => {
    return {
      ...availableTags.llm.tagger_params.tag_dict,
      ...ruleDict,
    };
  }, [availableTags.llm.tagger_params.tag_dict, ruleDict]);

  const completionPercentage = useMemo(() => {
    if (relatedInfo[categoryKey]) {
      return relatedInfo[categoryKey]
        ? (1 -
            relatedInfo[categoryKey].counter /
              Object.keys(currentDataGroup).length) *
            100
        : 0;
    }
    return 0;
  }, [relatedInfo, categoryKey, currentDataGroup]);

  useEffect(() => {
    const calculateRelatedInfo = () => {
      let info = {};

      Object.entries(tagDict).forEach(([key, value]) => {
        let counter = 0;
        let name = value.name;
        let matchingNames = [];

        Object.entries(currentDataGroup).forEach(([groupKey, groupValue]) => {
          if (!groupValue.hasOwnProperty(name)) {
            counter++;
            matchingNames.push(groupKey);
          }
        });

        info[key] = { name, counter, matchingNames };
      });

      setRelatedInfo(info);
    };

    calculateRelatedInfo();
  }, [currentDataGroup, setRelatedInfo, tagDict]);

  useEffect(() => {
    if (completionPercentage === 100 && selectedTagKeys.includes(categoryKey)) {
      setSelectedTagKeys((prevSelectedTagKeys) =>
        prevSelectedTagKeys.filter((key) => key !== categoryKey),
      );
    }
  }, [completionPercentage, categoryKey]);

  return (
    <div
      className={`flex relative bg-white border-b py-4 px-4 dark:border-b-zinc-600 dark:bg-zinc-800 dark:text-white`}
    >
      <div className="flex shrink-0 w-16 items-start justify-center ">
        <ProgressPie percentage={completionPercentage} />
      </div>
      <div
        className={`flex-1 w-full justify-between px-4 ${
          showScreen !== "catalog" && "menu-button-catalog"
        } ${
          isBeingDeleted
            ? "opacity-50 pointer-events-none"
            : "opacity-100 pointer-events-auto"
        }`}
      >
        <div>
          <div className="break-all justify-between flex-1">
            {" "}
            <span className="font-medium text-sm">{label}</span>
            {completionPercentage != 100 &&
              processingTags.map(({ label }) => label).includes(label) && (
                <div className="inline-block ml-1 mr-2">
                  <div className="animate-spin">
                    <CgSpinnerTwo />
                  </div>
                </div>
              )}
          </div>

          <div>
            <p className="py-2 text-sm">{tagDict[label]?.description}</p>
          </div>

          <div className="flex flex-col gap-1">
            <div className="flex flex-row">
              {tagDict[label]?.tagType === "rule" ? (
                <button
                  onClick={() => {
                    runSingleRule(currentDataGroup, tagDict[label].name);
                  }}
                  className={`text-xs px-2 py-1 font-medium rounded-md text-white ${
                    !relatedInfo?.[categoryKey]?.counter
                      ? "opacity-50"
                      : "opacity-100"
                  }
                      bg-primary
                  `}
                >
                  Run rule on{" "}
                  {selectedCatalogItems.size ||
                    relatedInfo[categoryKey]?.counter ||
                    0}{" "}
                  documents
                </button>
              ) : (
                <>
                  <div className="flex flex-row items-center w-full justify-end">
                    <PermissionGuard scope="tags" level="canEdit">
                      <>
                        <button
                          className="toggle-visibility-button pl-1"
                          onClick={(e) =>
                            toggleCategoryVisibility(e, categoryKey)
                          }
                          title={
                            hiddenCategories.includes(categoryKey)
                              ? "Unhide Category"
                              : "Hide Category"
                          }
                        >
                          {hiddenCategories.includes(categoryKey) ? (
                            <EyeOffIcon className="h-5 w-5 text-buttonGrey" />
                          ) : (
                            <EyeIcon className="h-5 w-5 text-buttonGrey" />
                          )}
                        </button>
                        <button
                          className="button-edit-button-labels text-buttonGrey bg-white"
                          onClick={(e) => editTag(e, categoryKey)}
                        >
                          <FontAwesomeIcon
                            icon={faEdit}
                            className="text-buttonGrey"
                          />
                        </button>
                        <button
                          className="text-buttonGrey bg-whit px-2"
                          onClick={(e) => deleteAllLabels(e, [categoryKey])}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                      </>
                    </PermissionGuard>
                  </div>
                </>
              )}
              {
                <span className="tooltip-text">
                  {relatedInfo[categoryKey]?.counter === 0
                    ? "All files are tagged"
                    : "Run tagging on all files untagged"}
                </span>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
